// src/components/FooterMobile.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import '../App.css';

function FooterMobile() {
  return (
    <Box 
      component="footer" 
      sx={{
        background: 'linear-gradient(135deg, #1A3D8F 0%, #2451B3 100%)',
        color: 'white',
        padding: '2rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '-24px'
      }}
    >
      {/* Tours */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
        <Link href="/tour/293823" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Beach Escape</Link>
        <Link href="/tour/296605" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>SeaWorld Explorer</Link>
        <Link href="/tour/296606" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Trolley Train City Center</Link>
        <Link href="/tour/301652" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Curaçao Island Tour</Link>
        <Link href="/tour/304151" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Historic Walking Tour</Link>
      </Box>

      {/* Contact */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', maxWidth: '300px' }}>
        <Typography variant="h6" sx={{ 
          fontSize: '2.5rem', 
          fontWeight: 800,
          color: 'white',
          mb: 2,
          letterSpacing: '0.5px'
        }}>
          Stay in touch
        </Typography>
        
        <Link 
          href="https://maps.google.com/?q=Seru+di+Mahuma+18-20,+Willemstad,+Curaçao"
          target="_blank"
          sx={{ 
            color: 'white', 
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}
        >
          <LocationOnIcon />
          <Typography>Seru di Mahuma #18 - 20</Typography>
        </Link>

        <Link 
          href="tel:+59998699559"
          sx={{ 
            color: 'white', 
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}
        >
          <PhoneIcon />
          <Typography>+5999 869 9559</Typography>
        </Link>

        <Link 
          href="mailto:info@fb-tt.com"
          sx={{ 
            color: 'white', 
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}
        >
          <EmailIcon />
          <Typography>info@fb-tt.com</Typography>
        </Link>
      </Box>

      {/* Copyright */}
      <Box sx={{ 
        borderTop: '1px solid rgba(255,255,255,0.2)', 
        paddingTop: '1rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.5rem'
      }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} Curaçao Tours
        </Typography>
        <Typography variant="body2" sx={{ opacity: 0.7 }}>
          Developed by <Link href="https://www.aimakers.co" target="_blank" sx={{ color: 'white' }}>AI Makers</Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default FooterMobile;
