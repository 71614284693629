import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';

const WhatsAppButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const phone = '59995153228';
  const message = encodeURIComponent("Hello, I'd like to inquire about the tours.");
  const whatsappUrl = `https://wa.me/${phone}?text=${message}`;

  const buttonStyle = {
    position: 'fixed',
    bottom: isMobile ? '100px' : '30px',
    right: '30px',
    zIndex: 999,
    backgroundColor: '#25D366',
    color: 'white',
    padding: '10px',
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  };

  return (
    <a 
      href={whatsappUrl} 
      target="_blank" 
      rel="noopener noreferrer" 
      style={buttonStyle}
    >
      <FontAwesomeIcon icon={faWhatsapp} size={isMobile ? "2x" : "3x"} />
    </a>
  );
};

export default WhatsAppButton;
