import React, { useEffect, useState } from 'react';
import { 
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Card,
    CardContent,
    Box,
    CircularProgress,
    Alert,
    Tabs,
    Tab
} from '@mui/material';
import { addDays, format } from 'date-fns';

const TodayToursDashboard = () => {
    const [tours, setTours] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateRange, setDateRange] = useState([]);

    // Initialize date range on component mount
    useEffect(() => {
        const today = new Date();
        const range = [];
        // Add 3 previous days, today, and tomorrow
        for (let i = -3; i <= 1; i++) {
            range.push(addDays(today, i));
        }
        setDateRange(range);
    }, []);

    const fetchToursForDate = async (date) => {
        setIsLoading(true);
        try {
            const year = date.getFullYear();
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_${year}!A2:G`;
            
            const response = await fetch(functionUrl);
            if (!response.ok) throw new Error('Failed to fetch');
            const result = await response.json();
            console.log('API Response:', result);
            const rows = result.data || [];
            console.log('Extracted Rows:', rows);

            const selectedDateStr = format(date, 'yyyy-MM-dd');
            console.log('Looking for date:', selectedDateStr);
            const filteredRows = rows.filter(row => row[6] === selectedDateStr);
            console.log('Filtered Rows:', filteredRows);

            // Convert the data into the format we need
            const toursData = filteredRows.map(row => ({
                title: row[3],
                company: row[1],
                agent: row[2],
                revenue: parseFloat(row[4]) || 0,
                pax: parseInt(row[5]) || 0
            }));

            // Sort tours by revenue (highest first)
            toursData.sort((a, b) => b.revenue - a.revenue);

            setTours(toursData);
        } catch (error) {
            console.error('Error fetching tours:', error);
            setTours([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedDate) {
            fetchToursForDate(selectedDate);
        }
    }, [selectedDate]);

    const handleDateChange = (_, newValue) => {
        setSelectedDate(dateRange[newValue]);
    };

    const getDateLabel = (date) => {
        const today = new Date();
        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        }
        if (date.toDateString() === addDays(today, 1).toDateString()) {
            return 'Tomorrow';
        }
        if (date.toDateString() === addDays(today, -1).toDateString()) {
            return 'Yesterday';
        }
        return format(date, 'EEE, MMM d');
    };

    const getTotalRevenue = () => {
        return tours.reduce((sum, tour) => sum + tour.revenue, 0);
    };

    const getTotalPax = () => {
        return tours.reduce((sum, tour) => sum + tour.pax, 0);
    };

    return (
        <Card sx={{ width: '100%', p: 2, backgroundColor: '#ffffff' }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Tours overview - {format(selectedDate, 'EEEE, MMMM d, yyyy')}
                </Alert>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                    <Tabs 
                        value={dateRange.findIndex(date => 
                            date.toDateString() === selectedDate.toDateString()
                        )}
                        onChange={handleDateChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="date navigation tabs"
                    >
                        {dateRange.map((date, index) => (
                            <Tab 
                                key={index} 
                                label={getDateLabel(date)}
                                sx={{
                                    fontWeight: date.toDateString() === new Date().toDateString() ? 'bold' : 'normal'
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6">
                                Tours Overview
                            </Typography>
                            {tours.length > 0 && (
                                <Box>
                                    <Typography variant="body1" color="textSecondary">
                                        Total Revenue: ${getTotalRevenue().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | 
                                        Total Participants: {getTotalPax()}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <TableContainer component={Paper} sx={{ 
                            boxShadow: 'none',
                            border: '1px solid #e0e0e0',
                            borderRadius: 1,
                            mt: 2
                        }}>
                            <Table aria-label="tours table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Sales Agent</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Revenue</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Participants</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tours.length > 0 ? (
                                        tours.map((tour, index) => (
                                            <TableRow 
                                                key={index}
                                                sx={{ '&:hover': { backgroundColor: '#f8f8f8' } }}
                                            >
                                                <TableCell>{tour.title}</TableCell>
                                                <TableCell>{tour.company}</TableCell>
                                                <TableCell>{tour.agent}</TableCell>
                                                <TableCell align="right">
                                                    ${tour.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </TableCell>
                                                <TableCell align="right">{tour.pax}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                                                <Typography variant="body1" color="textSecondary">
                                                    No tours scheduled for {format(selectedDate, 'MMMM d, yyyy')}.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default TodayToursDashboard;
