import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Card, CardContent, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Chip, Avatar, FormControlLabel, IconButton, Stack, Tooltip } from '@mui/material';
import { db, functions } from '../../config/firebase/firebase'; 
import { collection, doc, setDoc, getDocs, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { httpsCallable } from 'firebase/functions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';

function ActivityManager() {
  const [activities, setActivities] = useState([]);
  const [activitySelections, setActivitySelections] = useState({});
  const [rewrittenDescriptions, setRewrittenDescriptions] = useState({});
  const [editingActivity, setEditingActivity] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [openRewriteDialog, setOpenRewriteDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState('');

  useEffect(() => {
    const fetchActivities = async () => {
      const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
      if (response.ok) {
        const data = await response.json();
        setActivities(data);
      } else {
        console.error('Network response was not ok');
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchSavedSelections = async () => {
      const selectionsSnapshot = await getDocs(collection(db, 'activitySelections'));
      const fetchedSelections = {};
      selectionsSnapshot.forEach(doc => {
        fetchedSelections[doc.id] = doc.data();
      });
      setActivitySelections(fetchedSelections);
    };

    if (activities.length > 0) {
      fetchSavedSelections();
    }
  }, [activities]);

  useEffect(() => {
    const fetchRewrittenDescriptions = async () => {
      const rewrittenDocs = await getDocs(collection(db, 'rewrittenTourDescriptions'));
      const descriptions = {};
      rewrittenDocs.forEach(doc => {
        descriptions[doc.id] = doc.data();
      });
      setRewrittenDescriptions(descriptions);
    };

    fetchRewrittenDescriptions();
  }, []);

  const handleRewriteDescription = async (activity) => {
    setSelectedActivity(activity);
    setOpenRewriteDialog(true);
  };

  const handleCloseRewriteDialog = () => {
    setOpenRewriteDialog(false);
    setSelectedActivity(null);
    setAdditionalInfo('');
  };

  const handleSubmitRewrite = async () => {
    const rewriteDescription = httpsCallable(functions, 'rewriteTourDescriptions');
    try {
      const activity = selectedActivity;
      const priceInfo = activity.fromPrice ? `${activity.fromPrice.amount} ${activity.fromPrice.currency}` : 'Price not available';
      const combinedContent = `Title: ${activity.title}\nShort Title: ${activity.titleShort}\nPrice: ${priceInfo}\nDescription: ${activity.description}\nShort Description: ${activity.descriptionShort || ''}\nAdditional Information: ${additionalInfo}`.replace(/<[^>]+>/g, '');

      const requestData = {
        activityId: activity.id,
        messages: [{ role: "user", content: combinedContent }]
      };

      // Send the request and wait for the response
      await rewriteDescription(requestData);
      
      // Display thank you message
      toast.success('Thank you for submitting the rewrite request!');
      handleCloseRewriteDialog();
    } catch (error) {
      console.error('Error submitting rewrite request:', error);
      // Check if it's the specific "Response is missing data field" error
      if (error.message.includes('Response is missing data field')) {
        // This error is expected, so we'll treat it as a success
        toast.success('Thank you for submitting the rewrite request!');
        handleCloseRewriteDialog();
      } else {
        // For other errors, show an error message
        toast.error('Failed to submit rewrite request. Please try again.');
      }
    }
  };

  const handleSelectActivity = (activityId, category) => {
    setActivitySelections(prev => ({
      ...prev,
      [activityId]: {
        ...prev[activityId],
        [category]: !prev[activityId]?.[category]
      }
    }));
  };

  const handleSaveSelections = async () => {
    const activityDetailsCollection = collection(db, 'activityDetails');
    const activitySelectionsCollection = collection(db, 'activitySelections');
  
    try {
      const savePromises = [];
  
      for (const [activityId, selection] of Object.entries(activitySelections)) {
        const activity = activities.find(a => a.id.toString() === activityId);
        if (activity) {
          // Prepare activity data with image URLs
          const activityData = {
            ...activity,
            headerImage: activity.images.header?.M || '', // Fallback to empty string if undefined
            teaserImage: activity.images.teaser?.M || '', // Fallback to empty string if undefined
            galleryImages: activity.images.gallery?.map(img => img.M) || [], // Fallback to empty array if undefined
            originalPrice: activity.fromPrice // Include the original price directly
          };
  
          // Save activity details with images
          const activityDetailsDoc = doc(activityDetailsCollection, activityId);
          savePromises.push(setDoc(activityDetailsDoc, activityData));
  
          // Save selection state
          const selectionDoc = doc(activitySelectionsCollection, activityId);
          savePromises.push(setDoc(selectionDoc, selection));
        }
      }
  
      await Promise.all(savePromises);
      toast.success('Website updated!');
    } catch (error) {
      console.error('Error saving activity details:', error);
      toast.error('Failed to update the website.');
    }
  };

  const handleUpdateActivityDetails = async () => {
    const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
    if (response.ok) {
      const data = await response.json();
      const activityDetailsCollection = collection(db, 'activityDetails');
      const savePromises = data.map(activity => {
        const activityData = {
          ...activity,
          headerImage: activity.images.header?.M || '',
          teaserImage: activity.images.teaser?.M || '',
          galleryImages: activity.images.gallery?.map(img => img.M) || [],
          originalPrice: activity.fromPrice
        };
        const activityDetailsDoc = doc(activityDetailsCollection, activity.id.toString());
        return setDoc(activityDetailsDoc, activityData);
      });
      try {
        await Promise.all(savePromises);
        toast.success('Activity details updated!');
      } catch (error) {
        console.error('Error updating activity details:', error);
        toast.error('Failed to update activity details.');
      }
    } else {
      console.error('Network response was not ok');
    }
  };

  const handleEditActivity = async (activityId) => {
    const docRef = doc(db, 'rewrittenTourDescriptions', activityId.toString());
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      setEditingActivity(activityId);
      setEditedData(docSnap.data());
    } else {
      toast.error('No rewritten description found for this activity.');
    }
  };

  const handleSaveEdit = async () => {
    try {
      const docRef = doc(db, 'rewrittenTourDescriptions', editingActivity.toString());
      await setDoc(docRef, editedData);
      toast.success('Activity description updated successfully!');
      setEditingActivity(null);
    } catch (error) {
      console.error('Error updating activity description:', error);
      toast.error('Failed to update activity description.');
    }
  };

  const handleInputChange = (field, value) => {
    setEditedData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <Box sx={{ p: 3, maxWidth: '1600px', margin: '0 auto' }}>
      <Card elevation={3}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 500, color: 'primary.main' }}>
              Activity Manager
            </Typography>
            <Box>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleUpdateActivityDetails} 
                sx={{ mr: 2 }}
                startIcon={<RefreshIcon />}
              >
                Update Activities
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleSaveSelections}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
            </Box>
          </Box>

          <TableContainer 
            component={Paper} 
            sx={{ 
              mb: 3,
              boxShadow: 2,
              borderRadius: 2,
              '& .MuiTableCell-head': {
                backgroundColor: 'primary.main',
                color: 'white',
                fontWeight: 'bold'
              }
            }}
          >
            <Table aria-label="activities table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 250 }}>Activity Details</TableCell>
                  <TableCell align="center" sx={{ minWidth: 120 }}>Category</TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>Price</TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>Images</TableCell>
                  <TableCell align="center" sx={{ minWidth: 200 }}>Display Options</TableCell>
                  <TableCell align="center" sx={{ minWidth: 150 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((activity) => (
                  <TableRow 
                    key={activity.id}
                    sx={{ 
                      '&:hover': { 
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        transition: 'background-color 0.2s ease'
                      }
                    }}
                  >
                    <TableCell>
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                          {activity.title}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          ID: {activity.id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Chip 
                        label={activity.category?.name || 'No Category'} 
                        size="small"
                        sx={{ 
                          backgroundColor: 'primary.light',
                          color: 'white'
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">
                        {activity.fromPrice ? 
                          `${activity.fromPrice.amount} ${activity.fromPrice.currency}` : 
                          'Price not set'
                        }
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                        {activity.images.header && (
                          <Tooltip title="Header Image">
                            <Avatar
                              src={activity.images.header.M}
                              alt="Header"
                              variant="rounded"
                              sx={{ width: 40, height: 40 }}
                            />
                          </Tooltip>
                        )}
                        {activity.images.teaser && (
                          <Tooltip title="Teaser Image">
                            <Avatar
                              src={activity.images.teaser.M}
                              alt="Teaser"
                              variant="rounded"
                              sx={{ width: 40, height: 40 }}
                            />
                          </Tooltip>
                        )}
                        {activity.images.gallery && (
                          <Tooltip title={`${activity.images.gallery.length} Gallery Images`}>
                            <Box sx={{ position: 'relative' }}>
                              <Avatar
                                src={activity.images.gallery[0]?.M}
                                alt="Gallery"
                                variant="rounded"
                                sx={{ width: 40, height: 40 }}
                              />
                              {activity.images.gallery.length > 1 && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: -8,
                                    right: -8,
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    borderRadius: '50%',
                                    width: 20,
                                    height: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.75rem'
                                  }}
                                >
                                  +{activity.images.gallery.length - 1}
                                </Box>
                              )}
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                        {[
                          { label: 'Home Page', key: 'homePage' },
                          { label: 'Most Popular', key: 'mostPopular' },
                          { label: 'Transfers', key: 'transfers' },
                          { label: 'Local Deals', key: 'localDeals' },
                          { label: 'Special Offers', key: 'specialOffers' },
                          { label: 'Menu', key: 'displayInMenu' }
                        ].map(({ label, key }) => (
                          <Grid item xs={6} key={key}>
                            <FormControlLabel
                              control={
                                <Checkbox 
                                  checked={!!activitySelections[activity.id]?.[key]}
                                  onChange={() => handleSelectActivity(activity.id, key)}
                                  size="small"
                                />
                              }
                              label={<Typography variant="caption">{label}</Typography>}
                              sx={{ margin: 0 }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" spacing={1} justifyContent="center">
                        <Tooltip title="Rewrite Description">
                          <IconButton
                            color="primary"
                            onClick={() => handleRewriteDescription(activity)}
                            size="small"
                          >
                            <AutoFixHighIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={rewrittenDescriptions[activity.id] ? 'Edit Description' : 'No Description'}>
                          <span>
                            <IconButton
                              color="secondary"
                              onClick={() => handleEditActivity(activity.id)}
                              disabled={!rewrittenDescriptions[activity.id]}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Edit Dialog */}
      <Dialog 
        open={!!editingActivity} 
        onClose={() => setEditingActivity(null)} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          backgroundColor: 'primary.main',
          color: 'white'
        }}>
          Edit Activity Description
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          {Object.entries(editedData).map(([key, value]) => (
            <TextField
              key={key}
              label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              fullWidth
              multiline={typeof value === 'string' && value.length > 50}
              margin="normal"
              variant="outlined"
              sx={{ mb: 2 }}
            />
          ))}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
          <Button onClick={() => setEditingActivity(null)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rewrite Dialog */}
      <Dialog 
        open={openRewriteDialog} 
        onClose={handleCloseRewriteDialog}
        PaperProps={{
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          backgroundColor: 'primary.main',
          color: 'white'
        }}>
          Additional Information for AI Rewrite
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            id="additionalInfo"
            label="Important Information"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            variant="outlined"
            placeholder="Add any specific requirements or information for the AI rewrite..."
          />
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
          <Button onClick={handleCloseRewriteDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmitRewrite} variant="contained" color="primary">
            Submit for Rewrite
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ActivityManager;