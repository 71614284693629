import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Dynamic color generator with guaranteed separation
const generateDistinctColors = (totalDatasets, index) => {
  // Use golden ratio for maximum color separation
  const goldenRatio = 0.618033988749895;
  const hue = (index * goldenRatio * 360) % 360;
  
  return {
    backgroundColor: `hsla(${hue}, 70%, 60%, 0.2)`,
    borderColor: `hsl(${hue}, 70%, 45%)`
  };
};

const getDatasetColors = (datasetName, allDatasets) => {
  // Find the index of this dataset in the full list
  const index = allDatasets.findIndex(d => d.name === datasetName);
  return generateDistinctColors(allDatasets.length, index);
};

const DynamicChartComponent = () => {
  const [charts, setCharts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartTypes, setChartTypes] = useState({}); // Format: { chartId: { 2023: 'line', 2024: 'bar' } }
  const [hiddenDatasets, setHiddenDatasets] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState({});
  const [originalConfigs, setOriginalConfigs] = useState({});

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        console.log('Starting to fetch charts...');
        setLoading(true);
        const chartsCollection = collection(db, 'SardonyxPython');
        const chartsQuery = query(chartsCollection, orderBy('createdAt', 'desc'));
        console.log('Executing query...');
        const chartsSnapshot = await getDocs(chartsQuery);
        console.log('Query results:', chartsSnapshot.size, 'documents found');
        
        const chartsData = chartsSnapshot.docs
          .filter(doc => {
            const hasConfig = doc.data().chartConfig;
            console.log('Document', doc.id, hasConfig ? 'has config' : 'missing config');
            return hasConfig;
          })
          .map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              chartName: data.chartName,
              description: data.description,
              query: data.query,
              chartConfig: data.chartConfig,
              createdAt: data.createdAt?.toDate()
            };
          });

        setCharts(chartsData);
        
        // Initialize chart types for each year
        const initialChartTypes = {};
        chartsData.forEach(chart => {
          initialChartTypes[chart.id] = {
            2023: 'line',
            2024: 'line'
          };
        });
        setChartTypes(initialChartTypes);
        
        // Store original configurations
        const initialConfigs = {};
        chartsData.forEach(chart => {
          initialConfigs[chart.id] = {
            chartType: 'line', // Default type
            hiddenDatasets: {},
            chartConfig: chart.chartConfig
          };
        });
        setOriginalConfigs(initialConfigs);
        
        setError(null);
      } catch (error) {
        console.error('Error fetching charts:', error);
        setError('Failed to load charts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCharts();
  }, []);

  const toggleChartType = (chartId, year) => {
    setChartTypes(prev => ({
      ...prev,
      [chartId]: {
        ...prev[chartId],
        [year]: prev[chartId]?.[year] === 'line' ? 'bar' : 'line'
      }
    }));
    setHasUnsavedChanges(prev => ({ ...prev, [chartId]: true }));
  };

  const handleDatasetVisibilityChange = (chartId, datasetIndex, isHidden) => {
    setHiddenDatasets(prev => ({
      ...prev,
      [chartId]: {
        ...(prev[chartId] || {}),
        [datasetIndex]: isHidden
      }
    }));
    setHasUnsavedChanges(prev => ({ ...prev, [chartId]: true }));
  };

  const saveChartConfiguration = async (chartId) => {
    try {
      const chartRef = doc(db, 'SardonyxPython', chartId);
      
      // Get only the active (visible) datasets
      const currentChart = charts.find(c => c.id === chartId);
      const activeDatasets = currentChart.chartConfig.data.filter((_, index) => 
        !hiddenDatasets[chartId]?.[index]
      );

      // Update the chart config to only include visible datasets
      const updatedChartConfig = {
        ...currentChart.chartConfig,
        data: activeDatasets
      };
      
      const chartConfig = {
        chartType: chartTypes[chartId],
        hiddenDatasets: {}, // Reset hidden datasets as we're removing them
        chartConfig: updatedChartConfig
      };
      
      await updateDoc(chartRef, {
        savedConfig: chartConfig,
        chartConfig: updatedChartConfig // Update the main chart config as well
      });
      
      // Update local state
      setCharts(prevCharts => prevCharts.map(chart => 
        chart.id === chartId 
          ? { ...chart, chartConfig: updatedChartConfig }
          : chart
      ));
      
      // Update the original config with the new saved state
      setOriginalConfigs(prev => ({
        ...prev,
        [chartId]: {
          ...prev[chartId],
          chartType: chartTypes[chartId],
          hiddenDatasets: {},
          chartConfig: updatedChartConfig
        }
      }));
      
      // Reset hidden datasets for this chart as we've removed them
      setHiddenDatasets(prev => ({
        ...prev,
        [chartId]: {}
      }));
      
      setHasUnsavedChanges(prev => ({ ...prev, [chartId]: false }));
    } catch (error) {
      console.error('Error saving chart configuration:', error);
      setError('Failed to save chart configuration');
    }
  };

  const resetChartToOriginal = (chartId) => {
    const originalConfig = originalConfigs[chartId];
    if (originalConfig) {
      setChartTypes(prev => ({
        ...prev,
        [chartId]: originalConfig.chartType
      }));
      setHiddenDatasets(prev => ({
        ...prev,
        [chartId]: originalConfig.hiddenDatasets
      }));
      setHasUnsavedChanges(prev => ({ ...prev, [chartId]: false }));
    }
  };

  const getYearFromDate = (date) => {
    return date ? date.getFullYear() : new Date().getFullYear();
  };

  const renderChart = (chart) => {
    if (!chart.chartConfig || !chart.chartConfig.data) {
      console.log('Missing chart config for chart:', chart);
      return null;
    }

    const data = {
      labels: chart.chartConfig.data[0].x,
      datasets: chart.chartConfig.data.map((dataset, index) => {
        const colors = getDatasetColors(dataset.name, chart.chartConfig.data);
        const year = dataset.name.match(/202[34]/)?.[0];
        return {
          label: dataset.name,
          data: dataset.y,
          type: chartTypes[chart.id]?.[year] || 'line',
          hidden: hiddenDatasets[chart.id]?.[index] || false,
          ...colors,
          borderWidth: 2,
          tension: chartTypes[chart.id]?.[year] === 'line' ? 0.4 : 0
        };
      })
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          position: 'top',
          onClick: (e, legendItem) => {
            const index = legendItem.datasetIndex;
            handleDatasetVisibilityChange(chart.id, index, !legendItem.hidden);
          },
          labels: {
            filter: (legendItem, data) => {
              // Only show legend items for datasets that haven't been hidden and saved
              return !hiddenDatasets[chart.id]?.[legendItem.datasetIndex];
            },
            usePointStyle: true,
            padding: 16
          }
        },
        title: {
          display: true,
          text: chart.chartName,
          font: {
            size: 16,
            weight: '500'
          },
          padding: {
            bottom: 30
          }
        },
        tooltip: {
          enabled: true,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          titleColor: '#1e293b',
          bodyColor: '#475569',
          borderColor: '#e2e8f0',
          borderWidth: 1,
          padding: 12,
          boxPadding: 6
        }
      },
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          beginAtZero: true,
          grid: {
            color: '#e2e8f0'
          },
          ticks: {
            callback: function(value) {
              return value.toLocaleString();
            }
          }
        },
        x: {
          grid: {
            display: false
          }
        }
      }
    };

    // If the original type is 'bar', add bar-specific options
    if (chart.chartConfig.data.some(d => d.type === 'bar')) {
      options.scales.x.categoryPercentage = 0.8;
      options.scales.x.barPercentage = 0.9;
      options.plugins.tooltip.callbacks = {
        label: function(context) {
          return `${context.dataset.label}: ${context.parsed.y.toLocaleString()}`;
        }
      };
    }

    return (
      <div key={chart.id} className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{chart.chartName}</h3>
            {chart.description && (
              <p className="text-sm text-gray-600 mb-1">{chart.description}</p>
            )}
            <p className="text-xs text-gray-400">
              Created: {chart.createdAt?.toLocaleString()}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {hasUnsavedChanges[chart.id] && (
              <button
                onClick={() => saveChartConfiguration(chart.id)}
                className="p-2 text-blue-600 hover:text-blue-800 transition-colors relative group"
                aria-label="Save chart configuration"
              >
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                  Save chart configuration
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M3 17V5a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2zm2-2v2h10v-2H5zm0-10v8h10V5H5z"/>
                </svg>
              </button>
            )}
            <button
              onClick={() => resetChartToOriginal(chart.id)}
              className="p-2 text-gray-600 hover:text-gray-800 transition-colors relative group"
              aria-label="Reset chart to original state"
            >
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                Reset to original state
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
              </svg>
            </button>
            <div className="flex gap-2">
              <button
                onClick={() => toggleChartType(chart.id, '2023')}
                className="px-3 py-1 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              >
                2023: {chartTypes[chart.id]?.['2023'] === 'line' ? 'Line' : 'Bar'}
              </button>
              <button
                onClick={() => toggleChartType(chart.id, '2024')}
                className="px-3 py-1 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              >
                2024: {chartTypes[chart.id]?.['2024'] === 'line' ? 'Line' : 'Bar'}
              </button>
            </div>
          </div>
        </div>
        <div className="h-[400px]">
          <Line data={data} options={options} />
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-gray-600">Loading charts...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">
        {error}
      </div>
    );
  }

  if (charts.length === 0) {
    return (
      <div className="text-gray-600 text-center p-8">
        No charts found.
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6 max-w-[2000px] mx-auto">
      {charts.map(chart => renderChart(chart))}
    </div>
  );
};

export default DynamicChartComponent;
