import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, useTheme, useMediaQuery, Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GroupsIcon from '@mui/icons-material/Groups';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = matches;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/culinarymail1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject: `Contact Form Submission from ${formData.name}`,
          text: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
        })
      });

      if (response.ok) {
        if (window.dataLayer) {
          window.dataLayer.push({
            'event': 'form_submission',
            'form_name': 'contact_form',
            'form_status': 'success'
          });
        }
        
        setFormData({
          name: '',
          email: '',
          message: ''
        });

        navigate('/thank-you');
      } else {
        if (window.dataLayer) {
          window.dataLayer.push({
            'event': 'form_submission',
            'form_name': 'contact_form',
            'form_status': 'error',
            'error_type': 'server_error'
          });
        }
        const errorData = await response.text();
        console.error('Server response:', errorData);
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      if (window.dataLayer) {
        window.dataLayer.push({
          'event': 'form_submission',
          'form_name': 'contact_form',
          'form_status': 'error',
          'error_type': 'network_error'
        });
      }
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const ContactItem = ({ icon, text, link, label }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: 2,
      mb: { xs: 3, md: 4 },
      p: { xs: 1, md: 2 },
      borderRadius: 2,
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translateX(8px)',
      },
      '&:last-child': {
        mb: 0
      }
    }}>
      <Box sx={{ 
        color: BRAND_COLORS.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: { xs: '1.5rem', md: '1.75rem' },
        minWidth: '32px'
      }}>
        {icon}
      </Box>
      <Box>
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#666',
            mb: 0.5,
            fontSize: { xs: '0.85rem', md: '0.9rem' },
            textTransform: 'uppercase',
            letterSpacing: '0.5px'
          }}
        >
          {label}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#333',
            fontSize: { xs: '1rem', md: '1.1rem' },
            fontWeight: 500,
            '& a': {
              color: '#333',
              textDecoration: 'none',
              transition: 'color 0.2s',
              '&:hover': {
                color: BRAND_COLORS.blue
              }
            }
          }}
        >
          {link ? <a href={link} target="_blank" rel="noopener noreferrer">{text}</a> : text}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        width: '100vw', 
        height: isMobile ? '50vh' : '100vh', 
        position: 'relative',
        marginTop: '-64px',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        marginBottom: 4,
        overflow: 'hidden',
        zIndex: 0
      }}>
        <motion.img 
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          src="/images/Curacao_Tours_Contact.jpg"
          alt="Contact Us" 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} 
        />
        <motion.div 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 0.5 }}
          transition={{ duration: 0.8 }}
          style={{
            position: 'absolute',
            top: 0,
            left: -300,
            width: '75%',
            height: '100%',
            backgroundImage: 'url(/images/Overlay.svg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Box>

      <Container maxWidth="lg" sx={{ mb: 6, mt: -10, position: 'relative', zIndex: 2 }}>
        <Card sx={{ 
          borderRadius: 4,
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 2,
          backgroundColor: 'white'
        }}>
          <CardContent sx={{ p: { xs: 3, md: 6 }, pb: { xs: 3, md: 6 } }}>
            <Typography 
              variant={isMobile ? "h4" : "h2"} 
              component="h1" 
              align="center"
              gutterBottom 
              sx={{ 
                color: BRAND_COLORS.blue,
                fontWeight: 700,
                mb: 2,
                lineHeight: 1.2,
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
              }}
            >
              Contact Us
            </Typography>
            <Typography 
              variant="body1" 
              align="center"
              gutterBottom 
              sx={{ 
                color: '#666',
                mb: 6,
                fontSize: { xs: '1rem', sm: '1.1rem' }
              }}
            >
              We're here to help and answer any question you might have
            </Typography>

            <Grid container spacing={{ xs: 4, md: 6 }}>
              <Grid item xs={12} md={5}>
                <Box sx={{ mb: { xs: 2, md: 0 } }}>
                  <ContactItem 
                    icon={<WhatsAppIcon fontSize="inherit" />}
                    text="+5999 8699559"
                    label="WhatsApp"
                    link="https://wa.me/59998699559"
                  />
                  <ContactItem 
                    icon={<EmailIcon fontSize="inherit" />}
                    text="info@fb-tt.com"
                    label="Email"
                    link="mailto:info@fb-tt.com?subject=Inquiry%20from%20Website"
                  />
                  <ContactItem 
                    icon={<PhoneIcon fontSize="inherit" />}
                    text="+5999 8699559"
                    label="Phone"
                    link="tel:+59998699559"
                  />
                  <ContactItem 
                    icon={<LocationOnIcon fontSize="inherit" />}
                    text="Seru di Mahuma #18 - 20, Willemstad, Curaçao"
                    label="Address"
                    link="https://maps.google.com/?q=Seru+di+Mahuma+18-20,+Willemstad,+Curaçao"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box component="form" onSubmit={handleSubmit}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    label="Your Name"
                    name="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ 
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: '#f8f9fa'
                      }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ 
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: '#f8f9fa'
                      }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    name="message"
                    label="Message"
                    id="message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    sx={{ 
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: '#f8f9fa'
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{
                      py: 1.5,
                      backgroundColor: BRAND_COLORS.yellow,
                      color: BRAND_COLORS.blue,
                      fontWeight: 600,
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: BRAND_COLORS.lightYellow,
                      }
                    }}
                  >
                    {isSubmitting ? 'SENDING...' : 'SEND MESSAGE'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <Box sx={{ 
            borderTop: '1px solid rgba(0,0,0,0.1)',
            background: 'linear-gradient(180deg, rgba(26,61,143,0.03), rgba(26,61,143,0.08))'
          }}>
            <Box sx={{ p: { xs: 2, md: 6 }, pt: { xs: 4, md: 6 } }}>
              <Grid container spacing={{ xs: 2, md: 4 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: { xs: 2, md: 3 } }}>
                    <GroupsIcon sx={{ color: BRAND_COLORS.blue, fontSize: { xs: '2rem', md: '2.5rem' } }} />
                    <Typography variant="h5" sx={{ 
                      color: BRAND_COLORS.blue, 
                      fontWeight: 600,
                      fontSize: { xs: '1.5rem', md: '1.75rem' }
                    }}>
                      Group Bookings
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ 
                    color: '#555', 
                    lineHeight: 1.6, 
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    mb: { xs: 3, md: 0 }
                  }}>
                    Plan the perfect group tour with Curaçao Tours! Contact us for exclusive group packages and custom itineraries.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ 
                  display: 'flex', 
                  alignItems: { xs: 'flex-start', md: 'center' }
                }}>
                  <Box>
                    <ContactItem 
                      icon={<EmailIcon fontSize="inherit" />}
                      text="groups@fb-tt.com"
                      label="Group Inquiries"
                      link="mailto:groups@fb-tt.com?subject=Group%20Booking%20Inquiry"
                    />
                    <ContactItem 
                      icon={<PhoneIcon fontSize="inherit" />}
                      text="+5999 8699559"
                      label="Phone"
                      link="tel:+59998699559"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Container>
    </motion.div>
  );
}

export default Contact;
