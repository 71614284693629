import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, CircularProgress, Container, Card, useTheme } from '@mui/material';
import TourCard from './TourCard';
import recommendedTours from '../data/recommended-tours.json';

function RecommendedTours({ excludeTourId }) {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    try {
      // Convert tours object to array and filter out the excluded tour
      const toursArray = Object.entries(recommendedTours)
        .filter(([id]) => id !== excludeTourId)
        .map(([id, data]) => ({
          id,
          ...data
        }));

      // Randomly select up to 3 tours from the filtered list
      const shuffledTours = [...toursArray].sort(() => Math.random() - 0.5);
      const selectedTours = shuffledTours.slice(0, 3);

      setTours(selectedTours);
      setLoading(false);
    } catch (error) {
      console.error('Error loading recommended tour data:', error);
      setError('Failed to load recommended tours. Please try again later.');
      setLoading(false);
    }
  }, [excludeTourId]);

  const handleTourClick = (tourId) => {
    // First navigate to the tour
    navigate(`/tour/${tourId}`);
    // Then scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress sx={{ color: theme.brandColors.blue }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );
  }

  if (tours.length === 0) {
    return null;
  }

  return (
    <Card
      sx={{
        background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 4, md: 6 },
        mt: 0,
        mb: 0,
        borderRadius: { xs: '24px 24px 0 0', md: '24px 24px 0 0' },
        boxShadow: 'none',
        position: 'static'
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Typography 
          variant="h4" 
          component="h2"
          gutterBottom 
          sx={{ 
            color: theme.brandColors.blue,
            fontWeight: 700,
            mb: 4,
            textAlign: 'center',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
            px: { xs: 2, sm: 3 }
          }}
        >
          Recommended Tours
        </Typography>
        <Grid 
          container 
          spacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{
            px: { xs: 2, sm: 3 }
          }}
        >
          {tours.map((tour) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              key={tour.id}
            >
              <Box 
                onClick={() => handleTourClick(tour.id)} 
                sx={{ 
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)'
                  }
                }}
              >
                <TourCard tour={tour} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Card>
  );
}

export default RecommendedTours;
