import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement, 
    Title, 
    Tooltip, 
    Legend 
} from 'chart.js';
import { 
    Card, 
    CardContent, 
    Typography, 
    Box, 
    CircularProgress, 
    Alert,
    Chip,
    Collapse,
    IconButton,
    Tabs,
    Tab
} from '@mui/material';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import TimelineIcon from '@mui/icons-material/Timeline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyTourPax = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [selectedTours, setSelectedTours] = useState(['All Tours']);
    const [selectedYear, setSelectedYear] = useState('');
    const [tourOptions, setTourOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [tourPaxMap, setTourPaxMap] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());
    const [showTours, setShowTours] = useState(false);
    const [availableTours, setAvailableTours] = useState([]);

    const getTourColor = (tourName) => {
        const colorMap = {
            'All Tours': '#FF6384',
            'Beach Hopping': '#36A2EB',
            'Snorkel Trip': '#4BC0C0',
            'Private Tour': '#FFCE56',
            'Island Tour': '#9966FF',
            'Food Tour': '#FF9F40',
            'Walking Tour': '#FF6384',
            'Hiking Tour': '#4BC0C0'
        };
        
        if (colorMap[tourName]) return colorMap[tourName];
        
        // If tour not in map, generate a consistent color based on tour name
        let hash = 0;
        for (let i = 0; i < tourName.length; i++) {
            hash = tourName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        return `hsl(${h}, 70%, 60%)`;
    };

    const fetchYearData = async (year) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return {};
            }

            const data = monthlyDoc.data();
            let tourData = {};

            // Process monthly data
            for (let month = 1; month <= 12; month++) {
                if (data[month]?.tourStats) {
                    Object.entries(data[month].tourStats).forEach(([tourName, stats]) => {
                        if (!tourData[tourName]) {
                            tourData[tourName] = new Array(12).fill(0);
                        }
                        tourData[tourName][month - 1] = stats.pax;  // Use pax instead of revenue
                    });
                }
            }

            return tourData;
        } catch (error) {
            console.error(`Error fetching data for ${year}:`, error);
            return {};
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            const currentYear = currentDate.getFullYear();
            const years = Array.from({length: 4}, (_, i) => currentYear - i);
            let localTourPaxMap = {};

            for (const year of years) {
                const yearData = await fetchYearData(year);
                Object.entries(yearData).forEach(([tourName, monthlyData]) => {
                    if (!localTourPaxMap[tourName]) {
                        localTourPaxMap[tourName] = {};
                    }
                    localTourPaxMap[tourName][year] = monthlyData;
                });
            }

            setTourPaxMap(localTourPaxMap);
            setTourOptions(Object.keys(localTourPaxMap));
            setYearOptions(years);
            setSelectedTours(['All Tours']); // Default to All Tours
            setSelectedYear(years[0]); // Default to most recent year
            setIsLoading(false);
        };

        fetchAllData();
    }, [currentDate]);

    useEffect(() => {
        if (!selectedYear || !tourPaxMap) return;
        
        // Get tours that have data for the selected year
        const toursWithData = Object.entries(tourPaxMap)
            .filter(([_, yearData]) => yearData[selectedYear])
            .filter(([_, yearData]) => {
                // Check if tour has any non-zero pax in the selected year
                return yearData[selectedYear].some(pax => pax > 0);
            })
            .map(([tourName]) => tourName);

        setAvailableTours(toursWithData);
        
        // If none of the currently selected tours are available in new year, switch to All Tours
        setSelectedTours(prev => {
            const stillAvailable = prev.filter(tour => 
                tour === 'All Tours' || toursWithData.includes(tour)
            );
            return stillAvailable.length > 0 ? stillAvailable : ['All Tours'];
        });
    }, [selectedYear, tourPaxMap]);

    useEffect(() => {
        if (!selectedYear || !tourPaxMap) return;
        
        let datasets = [];
        
        if (selectedTours.includes('All Tours')) {
            const totalPax = new Array(12).fill(0);
            
            Object.entries(tourPaxMap).forEach(([tourName, tourData]) => {
                const yearData = tourData[selectedYear] || new Array(12).fill(0);
                yearData.forEach((pax, month) => {
                    totalPax[month] += pax;
                });
            });

            datasets = [{
                label: 'All Tours Combined',
                data: totalPax,
                borderColor: getTourColor('All Tours'),
                backgroundColor: getTourColor('All Tours'),
                tension: 0.4,
                fill: false
            }];
        } else {
            datasets = selectedTours.map(tourName => ({
                label: tourName,
                data: tourPaxMap[tourName]?.[selectedYear] || new Array(12).fill(0),
                borderColor: getTourColor(tourName),
                backgroundColor: getTourColor(tourName),
                tension: 0.4,
                fill: false
            }));
        }

        setChartData(prevData => ({
            ...prevData,
            datasets
        }));
    }, [selectedTours, selectedYear, tourPaxMap]);

    const handleYearChange = (event, newValue) => {
        setSelectedYear(newValue);
    };

    const handleTourSelection = (tourName) => {
        if (tourName === 'Select All') {
            // If Select All is clicked, select all available tours except 'All Tours'
            setSelectedTours(prev => {
                if (prev.length === availableTours.length) {
                    return ['All Tours'];
                }
                return [...availableTours];
            });
        } else if (tourName === 'All Tours') {
            setSelectedTours(['All Tours']);
        } else {
            setSelectedTours(prev => {
                const newSelection = prev.filter(t => t !== 'All Tours');
                if (newSelection.includes(tourName)) {
                    const filtered = newSelection.filter(t => t !== tourName);
                    return filtered.length === 0 ? ['All Tours'] : filtered;
                } else {
                    return [...newSelection, tourName];
                }
            });
        }
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: { size: 12 }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toLocaleString() + ' passengers';
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    color: '#e0e0e0'
                },
                title: {
                    display: true,
                    text: 'Number of Passengers',
                    font: { size: 12 }
                },
                ticks: {
                    callback: function(value) {
                        return value.toLocaleString();
                    }
                }
            },
            x: {
                grid: {
                    color: '#e0e0e0'
                }
            }
        }
    };

    return (
        <Card sx={{ width: '100%', p: 2, backgroundColor: '#ffffff' }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Monthly passenger breakdown by tour - updated {currentDate.toLocaleDateString('en-US', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })}
                </Alert>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Monthly Tour Passengers
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 1,
                                    cursor: 'pointer'
                                }} 
                                onClick={() => setShowTours(!showTours)}
                                >
                                    <TimelineIcon sx={{ mr: 1 }} />
                                    <Typography variant="subtitle2" component="span">
                                        Select Tours
                                    </Typography>
                                    <IconButton 
                                        size="small" 
                                        sx={{ ml: 1 }}
                                    >
                                        {showTours ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Box>
                                
                                <Collapse in={showTours}>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        flexWrap: 'wrap', 
                                        gap: 1,
                                        mt: 1,
                                        '& .MuiChip-root': {
                                            borderRadius: 1,
                                            backgroundColor: '#f0f0f0',
                                            '&.Mui-selected': {
                                                backgroundColor: '#1976d2',
                                                color: 'white'
                                            }
                                        }
                                    }}>
                                        <Chip
                                            label="All Tours"
                                            onClick={() => handleTourSelection('All Tours')}
                                            variant={selectedTours.includes('All Tours') ? 'filled' : 'outlined'}
                                            className={selectedTours.includes('All Tours') ? 'Mui-selected' : ''}
                                        />
                                        <Chip
                                            label="Select All"
                                            onClick={() => handleTourSelection('Select All')}
                                            variant={selectedTours.length === availableTours.length ? 'filled' : 'outlined'}
                                            className={selectedTours.length === availableTours.length ? 'Mui-selected' : ''}
                                        />
                                        {availableTours.map((tour) => (
                                            <Chip
                                                key={tour}
                                                label={tour}
                                                onClick={() => handleTourSelection(tour)}
                                                variant={selectedTours.includes(tour) ? 'filled' : 'outlined'}
                                                className={selectedTours.includes(tour) ? 'Mui-selected' : ''}
                                            />
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>

                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Select Year
                            </Typography>
                            <Box sx={{ 
                                borderBottom: 1, 
                                borderColor: 'divider',
                                '.MuiTabs-indicator': {
                                    height: 3
                                }
                            }}>
                                <Tabs 
                                    value={selectedYear} 
                                    onChange={handleYearChange}
                                    sx={{
                                        minHeight: 'auto',
                                        '& .MuiTab-root': {
                                            minHeight: 'auto',
                                            py: 1,
                                            px: 3,
                                            fontSize: '0.875rem'
                                        }
                                    }}
                                >
                                    {yearOptions.map((year) => (
                                        <Tab 
                                            key={year} 
                                            label={year} 
                                            value={year}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                        </Box>

                        <Box sx={{ height: '400px' }}>
                            <Line data={chartData} options={chartOptions} />
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default MonthlyTourPax;
