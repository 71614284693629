import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Card, CardContent, Typography, Box, CircularProgress, Alert, Chip, Collapse, IconButton, Tabs, Tab } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MonthlyAirportTransfers = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [selectedTransfers, setSelectedTransfers] = useState(['All Transfers']);
    const [selectedYear, setSelectedYear] = useState('');
    const [transferOptions, setTransferOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [transferPaxMap, setTransferPaxMap] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());
    const [showTransfers, setShowTransfers] = useState(false);
    const [availableTransfers, setAvailableTransfers] = useState([]);

    const getTransferColor = (transferName) => {
        const colorMap = {
            'All Transfers': '#FF6384',
            'Shared Airport Transfer': '#36A2EB',
            'Private Airport Transfer': '#4BC0C0',
            'Shared Airport Retour Transfer': '#FFCE56'
        };
        
        if (colorMap[transferName]) return colorMap[transferName];
        
        let hash = 0;
        for (let i = 0; i < transferName.length; i++) {
            hash = transferName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        return `hsl(${h}, 70%, 60%)`;
    };

    const fetchYearData = async (year) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return {};
            }

            const data = monthlyDoc.data();
            let transferData = {};
            const includedTours = ['Shared Airport Transfer', 'Private Airport Transfer', 'Shared Airport Retour Transfer'];

            // Process monthly data
            for (let month = 1; month <= 12; month++) {
                if (data[month]?.tourStats) {
                    Object.entries(data[month].tourStats)
                        .filter(([tourName]) => includedTours.includes(tourName))
                        .forEach(([tourName, stats]) => {
                            if (!transferData[tourName]) {
                                transferData[tourName] = new Array(12).fill(0);
                            }
                            transferData[tourName][month - 1] = stats.pax;
                        });
                }
            }

            return transferData;
        } catch (error) {
            console.error(`Error fetching data for ${year}:`, error);
            return {};
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            const currentYear = currentDate.getFullYear();
            const years = Array.from({length: 4}, (_, i) => currentYear - i);
            let localTransferPaxMap = {};

            for (const year of years) {
                const yearData = await fetchYearData(year);
                Object.entries(yearData).forEach(([transferName, monthlyData]) => {
                    if (!localTransferPaxMap[transferName]) {
                        localTransferPaxMap[transferName] = {};
                    }
                    localTransferPaxMap[transferName][year] = monthlyData;
                });
            }

            setTransferPaxMap(localTransferPaxMap);
            setTransferOptions(Object.keys(localTransferPaxMap));
            setYearOptions(years);
            setSelectedTransfers(['All Transfers']);
            setSelectedYear(years[0]);
            setIsLoading(false);
        };

        fetchAllData();
    }, [currentDate]);

    useEffect(() => {
        if (!selectedYear || !transferPaxMap) return;
        
        const transfersWithData = Object.entries(transferPaxMap)
            .filter(([_, yearData]) => yearData[selectedYear])
            .filter(([_, yearData]) => yearData[selectedYear].some(pax => pax > 0))
            .map(([transferName]) => transferName);

        setAvailableTransfers(transfersWithData);
        
        setSelectedTransfers(prev => {
            const stillAvailable = prev.filter(transfer => 
                transfer === 'All Transfers' || transfersWithData.includes(transfer)
            );
            return stillAvailable.length > 0 ? stillAvailable : ['All Transfers'];
        });
    }, [selectedYear, transferPaxMap]);

    useEffect(() => {
        if (!selectedYear || !transferPaxMap) return;

        let datasets = [];
        
        if (selectedTransfers.includes('All Transfers')) {
            const totalPax = new Array(12).fill(0);
            
            Object.entries(transferPaxMap).forEach(([transferName, transferData]) => {
                const yearData = transferData[selectedYear] || new Array(12).fill(0);
                yearData.forEach((pax, month) => {
                    totalPax[month] += pax;
                });
            });

            datasets = [{
                label: 'All Airport Transfers Combined',
                data: totalPax,
                borderColor: getTransferColor('All Transfers'),
                backgroundColor: getTransferColor('All Transfers'),
                tension: 0.4,
                fill: false,
                borderWidth: 2,
                pointRadius: 3,
                pointHoverRadius: 5
            }];
        } else {
            datasets = selectedTransfers.map(transferName => ({
                label: transferName,
                data: transferPaxMap[transferName]?.[selectedYear] || new Array(12).fill(0),
                borderColor: getTransferColor(transferName),
                backgroundColor: getTransferColor(transferName),
                tension: 0.4,
                fill: false,
                borderWidth: 2,
                pointRadius: 3,
                pointHoverRadius: 5
            }));
        }

        setChartData(prevData => ({
            ...prevData,
            datasets
        }));
    }, [selectedTransfers, selectedYear, transferPaxMap]);

    const handleTransferSelection = (transferName) => {
        if (transferName === 'All Transfers') {
            setSelectedTransfers(['All Transfers']);
        } else if (transferName === 'Select All') {
            // Select all available transfers except 'All Transfers'
            setSelectedTransfers(availableTransfers);
        } else {
            setSelectedTransfers(prev => {
                const newSelection = prev.filter(t => t !== 'All Transfers');
                if (newSelection.includes(transferName)) {
                    const filtered = newSelection.filter(t => t !== transferName);
                    return filtered.length === 0 ? ['All Transfers'] : filtered;
                } else {
                    return [...newSelection, transferName];
                }
            });
        }
    };

    const handleYearChange = (_, newValue) => {
        setSelectedYear(newValue);
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        size: 12
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + ' passengers';
                        }
                        return label;
                    }
                }
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#e0e0e0'
                },
                ticks: {
                    font: {
                        size: 12
                    }
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#e0e0e0'
                },
                ticks: {
                    callback: (value) => value + ' pax',
                    font: {
                        size: 12
                    }
                }
            }
        }
    };

    return (
        <Card sx={{ width: '100%', p: 2, backgroundColor: '#ffffff' }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Monthly passenger breakdown by airport transfer type - updated {currentDate.toLocaleDateString('en-US', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })}
                </Alert>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Monthly Airport Transfer Passengers
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 1,
                                    cursor: 'pointer'
                                }} 
                                onClick={() => setShowTransfers(!showTransfers)}
                                >
                                    <TimelineIcon sx={{ mr: 1 }} />
                                    <Typography variant="subtitle2" component="span">
                                        Select Transfer Types
                                    </Typography>
                                    <IconButton 
                                        size="small" 
                                        sx={{ ml: 1 }}
                                    >
                                        {showTransfers ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Box>
                                
                                <Collapse in={showTransfers}>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        flexWrap: 'wrap', 
                                        gap: 1,
                                        mt: 1,
                                        '& .MuiChip-root': {
                                            borderRadius: 1,
                                            backgroundColor: '#f0f0f0',
                                            '&.Mui-selected': {
                                                backgroundColor: '#1976d2',
                                                color: 'white'
                                            }
                                        }
                                    }}>
                                        <Chip
                                            label="All Transfers"
                                            onClick={() => handleTransferSelection('All Transfers')}
                                            variant={selectedTransfers.includes('All Transfers') ? 'filled' : 'outlined'}
                                            className={selectedTransfers.includes('All Transfers') ? 'Mui-selected' : ''}
                                        />
                                        <Chip
                                            label="Select All"
                                            onClick={() => handleTransferSelection('Select All')}
                                            variant={selectedTransfers.length === availableTransfers.length && !selectedTransfers.includes('All Transfers') ? 'filled' : 'outlined'}
                                            className={selectedTransfers.length === availableTransfers.length && !selectedTransfers.includes('All Transfers') ? 'Mui-selected' : ''}
                                        />
                                        {availableTransfers.map((transfer) => (
                                            <Chip
                                                key={transfer}
                                                label={transfer}
                                                onClick={() => handleTransferSelection(transfer)}
                                                variant={selectedTransfers.includes(transfer) ? 'filled' : 'outlined'}
                                                className={selectedTransfers.includes(transfer) ? 'Mui-selected' : ''}
                                            />
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>

                            <Tabs
                                value={selectedYear}
                                onChange={handleYearChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                {yearOptions.map((year) => (
                                    <Tab
                                        key={year}
                                        label={year}
                                        value={year}
                                    />
                                ))}
                            </Tabs>
                        </Box>

                        <Box sx={{ height: '400px' }}>
                            <Line data={chartData} options={chartOptions} />
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default MonthlyAirportTransfers;
