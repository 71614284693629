// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define your brand colors here so they can be used throughout your theme
const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

const theme = createTheme({
  // Extend palette with your brand colors
  palette: {
    primary: {
      main: BRAND_COLORS.blue,
      contrastText: '#fff'
    },
    secondary: {
      main: '#FF6F61',
      contrastText: '#fff'
    },
    // Additional standard roles (error, warning, info, success)
    error: {
      main: '#E53E3E'
    },
    warning: {
      main: '#DD6B20'
    },
    info: {
      main: '#3182CE'
    },
    success: {
      main: '#38A169'
    },
    background: {
      default: '#f0f0f0', // Light grey background
      paper: '#ffffff'
    },
    text: {
      primary: '#333',    // Primary text color
      secondary: '#555'   // Secondary text color
    }
  },

  // Make your BRAND_COLORS available for direct reference in components
  // by attaching it to the theme object
  brandColors: {
    blue: BRAND_COLORS.blue,
    yellow: BRAND_COLORS.yellow,
    lightBlue: BRAND_COLORS.lightBlue,
    lightYellow: BRAND_COLORS.lightYellow
  },

  typography: {
    fontFamily: [
      'Montserrat',
      'Arial',
      'sans-serif'
    ].join(','),
  },

  // Remove any hoverEffect from Cards, Buttons, Papers, AppBar, etc.
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '16px auto',
          maxWidth: {
            xs: '95%',
            sm: '90%',
            md: '800px'
          },
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '& .MuiCardContent-root': {
            padding: { xs: '12px', sm: '16px' },
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Remove hover scaling or custom transitions
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px',
          [`@media (max-width:600px)`]: {
            padding: '12px'
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          px: { xs: 2, sm: 3, md: 4 },
          mx: 'auto',
          width: {
            xs: '95%',
            sm: '90%',
            md: '85%'
          },
          maxWidth: {
            xs: '100%',
            sm: '90%',
            md: '1200px'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // intentionally empty to remove any custom hover effects
        }
      }
    }
  }
});

export default theme;