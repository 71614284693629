import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, Alert, Grid } from '@mui/material';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PersonIcon from '@mui/icons-material/Person';

const CurrentYearRevenue = () => {
    const [data, setData] = useState({
        2022: { revenue: 0, pax: 0 },
        2023: { revenue: 0, pax: 0 },
        2024: { revenue: 0, pax: 0 },
        2025: { revenue: 0, pax: 0 }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());

    const fetchYearData = async (year) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return { revenue: 0, pax: 0 };
            }

            const monthlyData = monthlyDoc.data();
            const currentMonth = currentDate.getMonth() + 1; // 1-based month

            let totalRevenue = 0;
            let totalPax = 0;
            for (let month = 1; month <= currentMonth; month++) {
                if (monthlyData[month]) {
                    totalRevenue += monthlyData[month].totalRevenue || 0;
                    totalPax += monthlyData[month].totalPax || 0;
                }
            }

            return { revenue: totalRevenue, pax: totalPax };
        } catch (error) {
            console.error(`Error fetching data for ${year}:`, error);
            return { revenue: 0, pax: 0 };
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const currentYear = currentDate.getFullYear();

        const fetchAllData = async () => {
            const years = [2022, 2023, 2024, currentYear];
            const results = await Promise.all(years.map(async year => {
                const yearData = await fetchYearData(year);
                return [year, yearData];
            }));

            const newData = Object.fromEntries(results);
            setData(newData);
            setIsLoading(false);
        };

        fetchAllData();
    }, [currentDate]);

    const calculateYoYChange = (currentValue, previousValue) => {
        if (previousValue === 0) return 100;
        return ((currentValue - previousValue) / previousValue) * 100;
    };

    const RevenueBox = ({ year, yearData, previousYearData, isMain }) => {
        const yoyChange = previousYearData ? calculateYoYChange(yearData.revenue, previousYearData.revenue) : null;
        
        return (
            <Card 
                elevation={isMain ? 3 : 1} 
                sx={{ 
                    p: 2,
                    backgroundColor: isMain ? '#f5f5f5' : 'white',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: 'scale(1.02)',
                    }
                }}
            >
                <Box>
                    <Typography variant={isMain ? "h6" : "subtitle1"} component="div" gutterBottom color="text.secondary">
                        {year} YTD Revenue
                    </Typography>
                    <Typography 
                        variant={isMain ? "h4" : "h5"} 
                        component="div" 
                        color={isMain ? "primary.main" : "text.primary"}
                        sx={{ fontWeight: isMain ? 'bold' : 'medium' }}
                    >
                        ${yearData.revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography>
                </Box>

                <Box sx={{ mt: 2 }}>
                    {yoyChange !== null && (
                        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                            {yoyChange > 0 ? (
                                <TrendingUpIcon sx={{ color: 'success.main', mr: 1 }} />
                            ) : (
                                <TrendingDownIcon sx={{ color: 'error.main', mr: 1 }} />
                            )}
                            <Typography 
                                variant="body2" 
                                color={yoyChange > 0 ? 'success.main' : 'error.main'}
                            >
                                {Math.abs(yoyChange).toFixed(1)}% vs previous year
                            </Typography>
                        </Box>
                    )}
                    
                    <Box display="flex" alignItems="center">
                        <PersonIcon sx={{ color: 'info.main', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                            {yearData.pax.toLocaleString()} passengers
                        </Typography>
                    </Box>
                </Box>
            </Card>
        );
    };

    return (
        <Card sx={{ width: '100%', p: 2 }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Figures shown are year-to-date as of {currentDate.toLocaleDateString()}
                </Alert>
                
                {isLoading ? (
                    <Box display="flex" alignItems="center" justifyContent="center" minHeight="200px">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <RevenueBox 
                                year={currentDate.getFullYear()} 
                                yearData={data[currentDate.getFullYear()]}
                                previousYearData={data[currentDate.getFullYear() - 1]}
                                isMain={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <RevenueBox 
                                year="2024" 
                                yearData={data[2024]}
                                previousYearData={data[2023]}
                                isMain={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <RevenueBox 
                                year="2023" 
                                yearData={data[2023]}
                                previousYearData={data[2022]}
                                isMain={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <RevenueBox 
                                year="2022" 
                                yearData={data[2022]}
                                previousYearData={null}
                                isMain={false}
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default CurrentYearRevenue;
