import React, { useState, useEffect } from 'react';
import TourCard from '../../components/TourCard';
import { Grid, Container, Box, Typography, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import transferTours from '../../data/transfer-tours.json';
import { motion } from 'framer-motion';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import StarIcon from '@mui/icons-material/Star';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

function Transfers() {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = matches;

  const FeatureBox = ({ icon, text }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 2,
      p: 2,
      borderRadius: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translateY(-5px)',
      }
    }}>
      <Box sx={{ 
        color: BRAND_COLORS.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '2rem'
      }}>
        {icon}
      </Box>
      <Typography variant="body1" sx={{ color: BRAND_COLORS.blue }}>
        {text}
      </Typography>
    </Box>
  );

  useEffect(() => {
    try {
      const transfersArray = Object.entries(transferTours).map(([id, data]) => ({
        id,
        ...data
      }));
      setTours(transfersArray);
    } catch (error) {
      console.error("Error loading tour data:", error);
      setError('Failed to load transfers. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        width: '100vw', 
        height: isMobile ? '50vh' : '100vh', 
        position: 'relative',
        marginTop: '-64px',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        marginBottom: 4,
        overflow: 'hidden'
      }}>
        <motion.img 
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          src="/images/Curacao_Tours_Transfers.jpg"
          alt="Airport Transfers" 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} 
        />
        <motion.div 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 0.5 }}
          transition={{ duration: 0.8 }}
          style={{
            position: 'absolute',
            top: 0,
            left: -300,
            width: '75%',
            height: '100%',
            backgroundImage: 'url(/images/Overlay.svg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Box>

      <Container maxWidth="lg" sx={{ mb: 6 }}>
        <Typography 
          variant={isMobile ? "h4" : "h2"} 
          component="h1" 
          align="center"
          gutterBottom 
          sx={{ 
            color: BRAND_COLORS.blue,
            fontWeight: 700,
            mb: 3,
            mt: 4,
            lineHeight: 1.2,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }
          }}
        >
          Airport Transfers
        </Typography>
        <Typography 
          variant={isMobile ? "h5" : "h3"} 
          component="h2" 
          align="center"
          gutterBottom 
          sx={{ 
            color: BRAND_COLORS.blue,
            fontWeight: 500,
            mb: 6,
            opacity: 0.9,
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.5rem' }
          }}
        >
          Comfortable and Reliable Transportation
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FeatureBox 
              icon={<AirportShuttleIcon fontSize="inherit" />}
              text="Modern air-conditioned vehicles"
            />
            <FeatureBox 
              icon={<StarIcon fontSize="inherit" />}
              text="Professional and experienced drivers"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 4,
                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                color: 'text.secondary',
                lineHeight: 1.6
              }}
            >
              Start and end your Curaçao journey smoothly with our reliable airport transfer service. We ensure a comfortable and timely journey between the airport and your accommodation.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }} justifyContent="center">
          {tours.map((tour) => (
            <Grid item xs={12} sm={6} md={4} key={tour.id}>
              <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
                <TourCard tour={tour} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
}

export default Transfers;
