import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Card, CardContent, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MonthlyParticipantsChart = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());

    const getYearColor = (year) => {
        // Define consistent colors for specific years
        const colorMap = {
            2025: '#FF6384', // Red
            2024: '#36A2EB', // Blue
            2023: '#4BC0C0', // Teal
            2022: '#FFCE56', // Yellow
            2021: '#9966FF'  // Purple
        };
        return colorMap[year];
    };

    const fetchYearData = async (year) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return null;
            }

            const data = monthlyDoc.data();
            const monthlyPax = new Array(12).fill(0);

            // Convert the data object to array
            for (let month = 1; month <= 12; month++) {
                if (data[month]) {
                    monthlyPax[month - 1] = data[month].totalPax || 0;
                }
            }

            return {
                label: `${year}`,
                data: monthlyPax,
                borderColor: getYearColor(year),
                backgroundColor: getYearColor(year),
                fill: false
            };
        } catch (error) {
            console.error(`Error fetching data for ${year}:`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            const currentYear = currentDate.getFullYear();
            // Get last 4 years including current year
            const years = Array.from({length: 4}, (_, i) => currentYear - i);
            
            const datasets = await Promise.all(years.map(fetchYearData));
            
            setChartData(prevData => ({
                ...prevData,
                datasets: datasets.filter(dataset => dataset !== null)
            }));
            setIsLoading(false);
        };

        fetchAllData();
    }, [currentDate]);

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        size: 12
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toLocaleString() + ' passengers';
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Number of Passengers',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    callback: function(value) {
                        return value.toLocaleString();
                    }
                }
            }
        }
    };

    return (
        <Card sx={{ 
            width: '100%', 
            p: 2,
            backgroundColor: '#ffffff',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.01)',
            }
        }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Historical monthly passenger trends for the past 4 years (updated {currentDate.toLocaleDateString('en-US', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })})
                </Alert>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom color="text.secondary">
                            Monthly Passengers by Year
                        </Typography>
                        <Box sx={{ height: '400px', mt: 2 }}>
                            <Line data={chartData} options={options} />
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default MonthlyParticipantsChart;
