import React from 'react';
import { Box, Grid, Typography, Link, Container, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link as RouterLink } from 'react-router-dom';
import Reviews from './reviews/index.js'; // Import the Reviews component
import '../App.css';

function Footer() {
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    marginRight: '15px',
  };
  const iconSize = { fontSize: '2.25rem', color: '#D3D3D3' }; // Reduced by 25% from 3rem to 2.25rem
  const iconBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(211, 211, 211, 0.35)', // Light grey with opacity
    borderRadius: '10%',
    width: '45px', // Reduced by 25% from 60px to 45px
    height: '45px', // Reduced by 25% from 60px to 45px
    margin: '0 6px', // Adjusted margin to maintain proportions
  };

  return (
    <Box component="footer" className="footer-background" sx={{ marginTop: '-24px' }}>
      <Box className="footer-red">
        <Reviews />
      </Box>
      <Box className="footer-blue" sx={{ width: '100%', py: 3, pt: 10 }}>
        <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4} justifyContent="space-between" alignItems="center" style={{ height: '100%' }}>
            
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '-20px' }}>
                <img 
                  src="/images/curacao_tours_white_logo.png" 
                  alt="Curaçao Tours Logo" 
                  style={{ height: '250px', marginRight: '10px' }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link href="https://www.curacao-tours.com/tour/293823" style={linkStyle}>Beach Escape</Link>
                <Link href="https://www.curacao-tours.com/tour/296605" style={linkStyle}>SeaWorld Explorer</Link>
                <Link href="https://www.curacao-tours.com/tour/296606" style={linkStyle}>Trolley Train City Center</Link>
                <Link href="https://www.curacao-tours.com/tour/301652" style={linkStyle}>Curaçao Island Tour</Link>
                <Link href="https://www.curacao-tours.com/tour/304151" style={linkStyle}>Historic Walking Tour</Link>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link href="https://www.curacao-tours.com/tour/311035" style={linkStyle}>Half Day Snorkel Tour by Boat</Link>
                <Link href="https://www.curacao-tours.com/tour/318608" style={linkStyle}>Off Road Truck Tour West</Link>
                <Link href="https://www.curacao-tours.com/tour/326864" style={linkStyle}>City E-Bike tour</Link>
                <Link href="https://www.curacao-tours.com/tour/326867" style={linkStyle}>Shopping Tour</Link>
                <Link href="https://www.curacao-tours.com/tour/326870" style={linkStyle}>Culinary Tour</Link>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <Container>
                <Box className="flex flex-col items-start">
                  <Typography variant="h6" sx={{ color: 'white', fontSize: '2rem', fontWeight: 'bold', mb: 2 }}>
                    Stay in touch
                  </Typography>
                  {/* Updated contact information with smaller text and new Google Maps link */}
                  <Typography variant="body2" sx={{ color: 'white', mb: 2, fontSize: '0.875rem' }}>
                    Seru di Mahuma #18 - 20, Willemstad, Curaçao<br />
                    <Link 
                      href="https://www.google.com/maps/place/FBTT+Travel/@12.1770075,-68.9569925,17z/data=!3m1!4b1!4m6!3m5!1s0x8e849d1c29ae33a7:0x9fade47df358888f!8m2!3d12.1770075!4d-68.9544176!16s%2Fg%2F11g9m4w1kc?entry=ttu" 
                      target="_blank" 
                      style={{ ...linkStyle, fontSize: '0.875rem' }}
                    >
                      View on Google Maps
                    </Link><br />
                    <Link href="tel:+59998699559" style={{ ...linkStyle, fontSize: '0.875rem' }}>
                      Phone: +5999 8699559
                    </Link><br />
                    <Link href="https://wa.me/59995146060" target="_blank" style={{ ...linkStyle, fontSize: '0.875rem' }}>
                      WhatsApp: +5999 5146060
                    </Link><br />
                    <Link href="mailto:info@fb-tt.com" style={{ ...linkStyle, fontSize: '0.875rem' }}>
                      Email: info@fb-tt.com
                    </Link>
                  </Typography>
                  <Box className="flex flex-col items-start w-full">
                    <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                      <Box sx={iconBoxStyle}>
                        <Link href="https://www.facebook.com/tabertours/" target="_blank" className="text-white hover:text-blue-600">
                          <FacebookIcon sx={iconSize} />
                        </Link>
                      </Box>
                      <Box sx={iconBoxStyle}>
                        <Link href="https://www.instagram.com/fbtttravel/" target="_blank" className="text-white hover:text-blue-600">
                          <InstagramIcon sx={iconSize} />
                        </Link>
                      </Box>
                      <Box sx={iconBoxStyle}>
                        <Link href="https://www.youtube.com/channel/UCmPkOtmz_5HP38Qm3yElh_w" target="_blank" className="text-white hover:text-blue-600">
                          <YouTubeIcon sx={iconSize} />
                        </Link>
                      </Box>
                      <Box sx={iconBoxStyle}>
                        <Link href="https://wa.me/59995153228" target="_blank" className="text-white hover:text-blue-600">
                          <WhatsAppIcon sx={iconSize} />
                        </Link>
                      </Box>
                    </Box>
                    <RouterLink to="/admin" style={{ width: '100%' }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: 'rgba(211, 211, 211, 0.35)', // Light grey with opacity
                          color: 'rgba(255, 255, 255, 0.5)', // White with 50% opacity
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          py: 1.5,
                          px: 3,
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: 'rgba(169, 169, 169, 0.35)', // Darker grey on hover
                          },
                          '&:focus': {
                            outline: 'none',
                          },
                          width: '100%',
                          mt: 2,
                        }}>
                        Admin Login
                      </Button>
                    </RouterLink>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Updated AI Makers bar */}
      <Box className="ai-makers-bar">
        Developed by <Link href="https://www.aimakers.co" target="_blank">AI Makers</Link>
      </Box>
    </Box>
  );
}

export default Footer;