import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PersonIcon from '@mui/icons-material/Person';

const CurrentMonthRevenue = () => {
    const [monthlyData, setMonthlyData] = useState({
        current: { revenue: 0, pax: 0 },
        previous: { revenue: 0, pax: 0 }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());

    const fetchMonthData = async (year, month) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return { revenue: 0, pax: 0 };
            }

            const data = monthlyDoc.data();
            return {
                revenue: data[month]?.totalRevenue || 0,
                pax: data[month]?.totalPax || 0
            };
        } catch (error) {
            console.error(`Error fetching data for ${year}/${month}:`, error);
            return { revenue: 0, pax: 0 };
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1; // 1-based month

            const [currentMonthData, previousYearData] = await Promise.all([
                fetchMonthData(currentYear, currentMonth),
                fetchMonthData(currentYear - 1, currentMonth)
            ]);

            setMonthlyData({
                current: currentMonthData,
                previous: previousYearData
            });
            setIsLoading(false);
        };

        fetchData();
    }, [currentDate]);

    const calculateYoYChange = (currentValue, previousValue) => {
        if (previousValue === 0) return 100;
        return ((currentValue - previousValue) / previousValue) * 100;
    };

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthName = monthNames[currentDate.getMonth()];
    const yoyChange = calculateYoYChange(monthlyData.current.revenue, monthlyData.previous.revenue);

    return (
        <Card sx={{ 
            width: '100%', 
            p: 2,
            backgroundColor: '#f5f5f5',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.02)',
            }
        }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Figures shown for {currentMonthName} {currentDate.getFullYear()} (as of {currentDate.toLocaleDateString('en-US', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })})
                </Alert>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom color="text.secondary">
                            Current Month Revenue
                        </Typography>
                        
                        <Typography variant="h4" component="div" color="primary.main" sx={{ fontWeight: 'bold', mb: 2 }}>
                            ${monthlyData.current.revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Typography>

                        <Box sx={{ mb: 2 }}>
                            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                                {yoyChange > 0 ? (
                                    <TrendingUpIcon sx={{ color: 'success.main', mr: 1 }} />
                                ) : (
                                    <TrendingDownIcon sx={{ color: 'error.main', mr: 1 }} />
                                )}
                                <Typography 
                                    variant="body2" 
                                    color={yoyChange > 0 ? 'success.main' : 'error.main'}
                                >
                                    {Math.abs(yoyChange).toFixed(1)}% vs {currentMonthName} {currentDate.getFullYear() - 1}
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                <PersonIcon sx={{ color: 'info.main', mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">
                                    {monthlyData.current.pax.toLocaleString()} passengers
                                </Typography>
                            </Box>
                        </Box>

                        <Typography variant="body2" color="text.secondary">
                            {currentMonthName} {currentDate.getFullYear() - 1}: ${monthlyData.previous.revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                            ({monthlyData.previous.pax.toLocaleString()} passengers)
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default CurrentMonthRevenue;
