import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Grid, Box, useTheme, Card } from '@mui/material';

import FilteredTours from '../../components/FilteredTours';
// Import the static JSON file
import allTours from '../../data/all-tours.json';

function HomePageTours() {
  const [selectedTours, setSelectedTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);

    try {
      // Get homepage tours
      const homepageTours = Object.entries(allTours)
        .filter(([id, tour]) => tour.homePage === true)
        .map(([id, tour]) => ({
          id,
          ...tour
        }));

      setSelectedTours(homepageTours);
      setLoading(false);
    } catch (error) {
      console.error('Error loading tours:', error);
      setError('Failed to load tours. Please try again later.');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress sx={{ color: theme.brandColors.blue }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography 
        color="error" 
        align="center"
        sx={{ 
          fontSize: { xs: '1rem', sm: '1.1rem' },
          py: 4
        }}
      >
        {error}
      </Typography>
    );
  }

  return (
    <Card
      sx={{
        background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 4, md: 6 },
        width: '100%',
        maxWidth: '100%',
        borderRadius: { xs: '24px 24px 0 0', md: '24px 24px 0 0' },
        boxShadow: 'none',
        position: 'static'
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
 
        <Box sx={{ 
          maxWidth: '1200px',
          mx: 'auto'
        }}>
          <FilteredTours tours={selectedTours} />
        </Box>
      </Container>
    </Card>
  );
}

export default HomePageTours;
