import React from 'react';
import { Box, Typography } from '@mui/material';

const CuracaoTourismBoard = () => {
  const powerBiUrl = "https://app.powerbi.com/view?r=eyJrIjoiOTlhYzE0MTYtMDI4Ni00NGM2LWFiMzUtNDYzYjc0M2UwNGQ4IiwidCI6Ijg2ZTY2MDY5LTcyMDEtNGNhOS05YWMwLWE3YjIyNDNkMzg2ZiJ9";

  return (
    <Box sx={{ width: '100%', height: '85vh', mt: 2 }}>

      <Box sx={{ 
        width: '100%', 
        height: 'calc(100% - 48px)', // Subtract the height of the title
        '& iframe': {
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }
      }}>
        <iframe
          title="Curacao Tourism Board Dashboard"
          width="100%"
          height="100%"
          src={powerBiUrl}
          allowFullScreen
        />
      </Box>
    </Box>
  );
};

export default CuracaoTourismBoard; 