import React from 'react';
import { Card, Grid, CardContent, Typography, Box, Tab, Tabs, AppBar } from '@mui/material';
import TodayToursDashboard from '../../components/dashboard/TodayToursDashboard';
import MonthlyRevenueChart from '../../components/dashboard/MonthlyRevenueChart';
import MonthlyTourRevenue from '../../components/dashboard/MonthlyTourRevenue';
import MonthlyTourPax from '../../components/dashboard/MonthlyTourPax';
import MonthlyTourPaxResmark from '../../components/dashboard/MonthlyTourPaxResmark';
import MonthlyTourPaxTreksoft from '../../components/dashboard/MonthlyTourPaxTreksoft';
import MonthlyRevenueByAgent from '../../components/dashboard/MonthlyRevenueByAgent';
import MonthlyParticipantsChart from '../../components/dashboard/MonthlyParticipantsChart';
import MonthlyRevenueByCompany from '../../components/dashboard/MonthlyPassengersByCompany';
import CurrentYearRevenue from '../../components/dashboard/CurrentYearRevenueTreksoft';
import CurrentMonthRevenue from '../../components/dashboard/CurrentMonthRevenueTreksoft';
import MonthlyAirportTransfers from '../../components/dashboard/MonthlyAirportTransfers';
import MonthlyRevenueByTourDesk from '../../components/dashboard/MonthlyRevenueByTourDesk';
import MonthlyPaxByAgent from '../../components/dashboard/MonthlyPaxByAgent';
import DynamicDashboards from '../DynamicDashboards';
import CuracaoTourismBoard from '../../components/dashboard/CuracaoTourismBoard';

const DashboardPage = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Dashboard
      </Typography>
      <AppBar position="static" sx={{ backgroundColor: '#424242' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="dashboard tabs"
          indicatorColor="secondary"
          textColor="inherit"
          sx={{
            '.MuiTab-root': {
              color: '#fff',
              '&.Mui-selected': {
                color: '#ffeb3b',
                fontWeight: 'bold',
              },
              '&:hover': {
                color: '#cddc39',
                opacity: 1,
              },
            }
          }}
        >
          <Tab label="Tour Sales" />
          <Tab label="Sardonyx" />
          <Tab label="Curacao Tourism Board" />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <Grid container spacing={2}>
          {/* Tour Sales tab content remains unchanged */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Current Year Revenue
                </Typography>
                <CurrentYearRevenue />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Current Month Revenue
                </Typography>
                <CurrentMonthRevenue />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyRevenueChart />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyParticipantsChart />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyTourRevenue />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyTourPax />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                {/* TODO: Implement Monthly Tour Participants (Resmark) component */}
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Tour Participants MARK NEEDS TO UPDATE (Resmark)
                </Typography>
                <MonthlyTourPaxResmark />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyTourPaxTreksoft />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyPaxByAgent />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyRevenueByAgent />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyRevenueByTourDesk />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyRevenueByCompany />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <MonthlyAirportTransfers />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <TodayToursDashboard />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {tabValue === 1 && (
        <DynamicDashboards />
      )}
      {tabValue === 2 && (
        <Box sx={{ width: '100%' }}>
          <CuracaoTourismBoard />
        </Box>
      )}
    </Box>
  );
};

export default DashboardPage;
