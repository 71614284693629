import React, { useState } from 'react';
import { Box, Chip, Grid, Container, Typography, Card, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import TourCard from './TourCard';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CultureIcon from '@mui/icons-material/Museum';
import CityIcon from '@mui/icons-material/LocationCity';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CATEGORIES = {
  ALL: 'All Tours',
  WATER: 'Water Activities',
  CULTURAL: 'Cultural & Historical',
  CITY: 'City Exploration',
  CULINARY: 'Culinary',
};

const DURATION_FILTERS = {
  SHORT: 'Short (1-2 hrs)',
  MEDIUM: 'Medium (2-4 hrs)',
  LONG: 'Long (4+ hrs)',
};

const FilteredTours = ({ tours }) => {
  const [selectedFilters, setSelectedFilters] = useState([CATEGORIES.ALL]);
  const theme = useTheme();

  const getFilterIcon = (filter) => {
    switch (filter) {
      case CATEGORIES.ALL:
        return <FilterAltIcon />;
      case CATEGORIES.WATER:
        return <BeachAccessIcon />;
      case CATEGORIES.CULTURAL:
        return <CultureIcon />;
      case CATEGORIES.CITY:
        return <CityIcon />;
      case CATEGORIES.CULINARY:
        return <RestaurantIcon />;
      default:
        return <AccessTimeIcon />;
    }
  };

  const getDurationCategory = (duration) => {
    const hours = parseFloat(duration);
    if (hours <= 2) return DURATION_FILTERS.SHORT;
    if (hours <= 4) return DURATION_FILTERS.MEDIUM;
    return DURATION_FILTERS.LONG;
  };

  const getCategoryForTour = (tour) => {
    const title = tour.title.toLowerCase();
    if (title.includes('beach') || title.includes('snorkel') || title.includes('sea') || title.includes('cruise')) {
      return CATEGORIES.WATER;
    }
    if (title.includes('historic') || title.includes('island tour')) {
      return CATEGORIES.CULTURAL;
    }
    if (title.includes('trolley') || title.includes('e-bike') || title.includes('city')) {
      return CATEGORIES.CITY;
    }
    if (title.includes('culinary')) {
      return CATEGORIES.CULINARY;
    }
    return null;
  };

  const handleFilterClick = (filter) => {
    if (filter === CATEGORIES.ALL) {
      setSelectedFilters([CATEGORIES.ALL]);
      return;
    }

    setSelectedFilters(prev => {
      const withoutAll = prev.filter(f => f !== CATEGORIES.ALL);
      
      if (prev.includes(filter)) {
        const newFilters = withoutAll.filter(f => f !== filter);
        return newFilters.length === 0 ? [CATEGORIES.ALL] : newFilters;
      } else {
        return [...withoutAll, filter];
      }
    });
  };

  const filteredTours = tours.filter(tour => {
    if (selectedFilters.includes(CATEGORIES.ALL)) return true;
    
    const tourCategory = getCategoryForTour(tour);
    const tourDuration = getDurationCategory(tour.duration);
    
    return selectedFilters.some(filter => 
      filter === tourCategory || filter === tourDuration
    );
  });

  const getChipStyles = (filter) => {
    const isSelected = selectedFilters.includes(filter);
    return {
      backgroundColor: isSelected ? theme.brandColors.blue : 'white',
      color: isSelected ? 'white' : theme.brandColors.blue,
      border: `1px solid ${theme.brandColors.blue}20`,
      boxShadow: isSelected ? `0 4px 12px ${theme.brandColors.blue}30` : 'none',
      transition: 'all 0.3s ease-in-out',
      '& .MuiSvgIcon-root': {
        color: isSelected ? 'white' : theme.brandColors.blue,
        transition: 'all 0.3s ease-in-out',
        marginRight: '4px',
      },
      '&:hover': {
        backgroundColor: isSelected 
          ? theme.brandColors.lightBlue 
          : `${theme.brandColors.blue}10`,
        transform: 'translateY(-2px)',
        boxShadow: `0 6px 16px ${theme.brandColors.blue}25`,
        '& .MuiSvgIcon-root': {
          color: isSelected ? 'white' : theme.brandColors.blue,
          transform: 'scale(1.1)',
        }
      },
      '&:active': {
        transform: 'translateY(0)',
      }
    };
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card 
        component={motion.div}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        sx={{ 
          background: '#E6E9F3',
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
          borderRadius: '16px',
          mb: 3,
          overflow: 'visible',
          width: '100%'
        }}
      >
               <Typography 
          variant="h3" 
          component="h2"
          gutterBottom 
          sx={{ 
            color: theme.brandColors.blue,
            fontWeight: 700,
            mb: 2,
            textAlign: 'center',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
            px: { xs: 2, sm: 3 }
          }}
        >
          Our Tours
        </Typography>

        <Box sx={{ p: { xs: '8px', sm: '12px' } }}>
          <Box 
            component={motion.div}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 1,
              justifyContent: 'center'
            }}>
              {Object.values(CATEGORIES).map((category) => (
                <Chip
                  key={category}
                  label={category}
                  icon={getFilterIcon(category)}
                  onClick={() => handleFilterClick(category)}
                  sx={{
                    ...getChipStyles(category),
                    fontSize: '0.875rem',
                    height: 36,
                    px: 1,
                    fontWeight: 600,
                    '& .MuiChip-label': {
                      padding: '0 8px 0 4px'
                    }
                  }}
                />
              ))}
            </Box>

            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 1,
              justifyContent: 'center'
            }}>
              {Object.values(DURATION_FILTERS).map((duration) => (
                <Chip
                  key={duration}
                  label={duration}
                  icon={<AccessTimeIcon />}
                  onClick={() => handleFilterClick(duration)}
                  sx={{
                    ...getChipStyles(duration),
                    fontSize: '0.875rem',
                    height: 36,
                    px: 1,
                    fontWeight: 600,
                    '& .MuiChip-label': {
                      padding: '0 8px 0 4px'
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Card>

      <Grid 
        component={motion.div}
        layout
        container 
        spacing={{ xs: 2, sm: 3, md: 4 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {filteredTours.map((tour) => (
          <Grid 
            item 
            key={tour.id} 
            xs={12} 
            sm={6} 
            md={4}
            component={motion.div}
            layout
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
          >
            <Link to={`/tour/${tour.id}`} style={{ textDecoration: 'none' }}>
              <TourCard tour={tour} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FilteredTours; 