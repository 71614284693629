// src/components/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const EXPIRATION_HOURS = 72;
const CORRECT_PASSWORD = 'admin'; // Replace with your actual password

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const checkStoredAuth = () => {
    const storedAuth = localStorage.getItem('authData');
    if (storedAuth) {
      const { timestamp } = JSON.parse(storedAuth);
      const now = new Date().getTime();
      const hoursElapsed = (now - timestamp) / (1000 * 60 * 60);
      
      if (hoursElapsed < EXPIRATION_HOURS) {
        return true;
      } else {
        localStorage.removeItem('authData');
      }
    }
    return false;
  };

  const storeAuth = () => {
    const authData = {
      timestamp: new Date().getTime()
    };
    localStorage.setItem('authData', JSON.stringify(authData));
  };

  useEffect(() => {
    const isStoredAuth = checkStoredAuth();
    
    if (isStoredAuth) {
      setIsAuthenticated(true);
      setIsAuthChecked(true);
      return;
    }

    const password = prompt('Please enter the password to access this page:');
    
    if (password === CORRECT_PASSWORD) {
      setIsAuthenticated(true);
      storeAuth();
    } else {
      alert('Incorrect password!');
    }
    setIsAuthChecked(true);
  }, []);

  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
