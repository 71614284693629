import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

function ThankYou() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = matches;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Container maxWidth="md" sx={{ 
        minHeight: '60vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        py: 8 
      }}>
        <Box sx={{ 
          textAlign: 'center',
          p: 4,
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
        }}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <CheckCircleIcon sx={{ 
              fontSize: { xs: '4rem', md: '6rem' }, 
              color: BRAND_COLORS.blue,
              mb: 2
            }} />
          </motion.div>
          
          <Typography 
            variant={isMobile ? "h4" : "h3"} 
            component="h1" 
            sx={{ 
              color: BRAND_COLORS.blue,
              fontWeight: 700,
              mb: 2
            }}
          >
            Thank You for Contacting Us!
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#666',
              mb: 4,
              fontSize: { xs: '1rem', md: '1.1rem' }
            }}
          >
            We've received your message and will get back to you shortly. 
            Meanwhile, feel free to explore more of our tours.
          </Typography>

          <Button
            component={Link}
            to="/"
            variant="contained"
            sx={{
              backgroundColor: BRAND_COLORS.yellow,
              color: BRAND_COLORS.blue,
              fontWeight: 600,
              px: 4,
              py: 1.5,
              '&:hover': {
                backgroundColor: BRAND_COLORS.lightYellow,
              }
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </motion.div>
  );
}

export default ThankYou; 