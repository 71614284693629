import React from 'react';
import DynamicChartComponent from '../../components/DynamicChartComponent';
const DynamicDashboards = () => {
  return (
    <div className="dynamic-dashboards" style={{ marginTop: '50px' }}>
    
      <DynamicChartComponent />
    </div>
  );
};

export default DynamicDashboards;