import React from 'react';
import { Helmet } from 'react-helmet';
import HomePageTours from '../HomePageTours';
import { Grid, Container, Box, Typography, Button, useMediaQuery, Card, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import ExploreIcon from '@mui/icons-material/Explore';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const FeatureBox = ({ icon, text }) => (
    <motion.div
      whileHover={{ y: -3 }}
      transition={{ duration: 0.2 }}
    >
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mb: 0,
          p: { xs: 1, md: 1.5 },
          borderRadius: 1.5,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          transition: 'box-shadow 0.2s ease-in-out',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08)',
          }
        }}
      >
        <Box sx={{ 
          color: theme.brandColors.blue,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: { xs: '1.25rem', md: '1.5rem' }
        }}>
          {icon}
        </Box>
        <Typography 
          variant="body2"
          sx={{ 
            color: theme.brandColors.blue,
            fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' },
            fontWeight: 500,
            lineHeight: 1.2
          }}
        >
          {text}
        </Typography>
      </Paper>
    </motion.div>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>Curacao Tours - Discover the Beauty of Curacao</title>
        <meta name="description" content="Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao. Book your next adventure today and create unforgettable memories." />
        <meta name="keywords" content="Curacao tours, Curacao travel, Curacao adventures, island tours, snorkeling tours, cultural tours, Curacao excursions" />
        <link rel="canonical" href="http://curacao-tours.com" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "TravelAgency",
              "name": "Curacao Tours",
              "description": "Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao.",
              "url": "http://curacao-tours.com",
              "logo": "http://curacao-tours.com/logo192.png",
              "telephone": "+59998699559",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Seru di Mahuma #18 - 20",
                "addressLocality": "Willemstad",
                "addressRegion": "Curaçao",
                "postalCode": "0000",
                "addressCountry": "CW"
              },
              "sameAs": [
                "https://www.facebook.com/curacao.tours",
                "https://www.instagram.com/curacao.tours",
                "https://www.twitter.com/curacao.tours"
              ]
            }
          `}
        </script>
      </Helmet>

      <Box sx={{
        background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 2, md: 3 },
        mb: { xs: -2, md: -3 },
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Container maxWidth="lg">
          <Card sx={{
            backgroundColor: 'transparent',
            borderRadius: 2,
            boxShadow: 'none',
            overflow: 'visible',
          }}>
            <Box sx={{ p: { xs: 0.5, sm: 1, md: 2 } }}>
              <Grid 
                container 
                spacing={{ xs: 1, md: 2 }}
                alignItems="flex-start"
              >
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <Typography 
                      variant={isMobile ? "h4" : "h2"} 
                      component="h1" 
                      gutterBottom 
                      sx={{ 
                        color: theme.brandColors.blue,
                        fontWeight: 800,
                        mb: 1,
                        lineHeight: 1,
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                        letterSpacing: '-0.02em'
                      }}
                    >
                      Welcome to Curaçao Tours
                    </Typography>
                    <Typography 
                      variant={isMobile ? "h5" : "h3"} 
                      component="h2" 
                      gutterBottom 
                      sx={{ 
                        color: theme.brandColors.blue,
                        fontWeight: 600,
                        mb: 1,
                        opacity: 0.85,
                        fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.5rem' },
                        lineHeight: 1.1,
                        display: { xs: 'none', sm: 'block' }
                      }}
                    >
                      Your Gateway to Unforgettable Adventures
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 2,
                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                        color: 'text.secondary',
                        lineHeight: 1.3,
                        maxWidth: '600px',
                        display: { xs: 'none', sm: 'block' }
                      }}
                    >
                      Discover Curaçao's vibrant beauty and rich culture with us! Our expert team creates memorable experiences tailored to every traveler's taste.
                    </Typography>
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={scrollToSection}
                        sx={{
                          backgroundColor: theme.brandColors.yellow,
                          color: theme.brandColors.blue,
                          fontWeight: 700,
                          px: { xs: 2, md: 3 },
                          py: { xs: 0.75, md: 1 },
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          borderRadius: 2,
                          textTransform: 'none',
                          boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                          '&:hover': {
                            backgroundColor: theme.brandColors.lightYellow,
                            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                          }
                        }}
                      >
                        Explore Tours
                      </Button>
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    <Box sx={{ 
                      mt: { xs: 2, md: 0 },
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr 1fr', md: '1fr' },
                      gap: { xs: 1, md: 1.5 }
                    }}>
                      <FeatureBox 
                        icon={<DirectionsBusIcon fontSize="inherit" />}
                        text={isMobile ? "Group Tours" : "Comfortable, air-conditioned buses for group excursions"}
                      />
                      <FeatureBox 
                        icon={<DirectionsBoatIcon fontSize="inherit" />}
                        text={isMobile ? "Beach Escapes" : "Exciting water adventures and beach escapes"}
                      />
                      <FeatureBox 
                        icon={<ExploreIcon fontSize="inherit" />}
                        text={isMobile ? "City Tours" : "Cultural experiences and historic city tours"}
                      />
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Container>
      </Box>

      <Box 
        id="target-section"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 0, md: 0 }
        }}
      >
        <HomePageTours />
      </Box>
    </motion.div>
  );
}