// src/pages/TemplateTourPage.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Container
} from '@mui/material';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from 'config/firebase/firebase';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'; // Example if you want more icons
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { motion } from 'framer-motion';

import TrekksoftBookingWidget from 'hooks/useTrekksoftLoader';
import RecommendedTours from './RecommendedTours';
import FooterMobile from 'components/FooterMobile.js'; // If you still use it
import tourImages from '../data/tour-images.json';
import { extractTourId } from '../utils/urlUtils';
import FloatingBookingWidget from './FloatingBookingWidget';

function TemplateTourPage() {
  // 1. Hooks & Data
  const { tourId: urlParam } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Extract the actual tour ID from either format
  const tourId = extractTourId(`/tour/${urlParam}`) || urlParam;

  const [tourDetails, setTourDetails] = useState({
    headerImage: '',
    shortTitle: '',
    shortDescription: '',
    duration: '',
    price: '',
    highlights: [],
    travelerTips: [],
    requirements: [],
    included: [],
    days: '',
    description: ''
  });

  const [galleryImages, setGalleryImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [recommendedTours, setRecommendedTours] = useState([]);

  // Add scroll position tracking
  const [isScrolled, setIsScrolled] = useState(false);

  // Add this effect to handle WhatsApp button positioning
  const [showBookingWidget, setShowBookingWidget] = useState(false);

  // 2. Fetching Data
  useEffect(() => {
    const fetchTourDetailsAndImages = async () => {
      try {
        const tourRef = doc(db, 'rewrittenTourDescriptions', tourId);
        const tourSnap = await getDoc(tourRef);

        if (tourSnap.exists()) {
          const data = tourSnap.data();
          let includedData = data.included || [];
          if (typeof includedData === 'string') {
            includedData = includedData.split(',').map(item => item.trim());
          }

          setTourDetails({
            headerImage: tourImages[tourId]?.header || '',
            shortTitle: data.shortTitle || '',
            shortDescription: data.shortDescription || '',
            duration: data.duration || '',
            price: data.price || '',
            highlights: data.highlights || [],
            travelerTips: data.travelerTips || [],
            requirements: data.requirements || [],
            included: includedData,
            days: data.days || '',
            description: data.description || ''
          });

          setGalleryImages(tourImages[tourId]?.gallery || []);
        } else {
          console.log('No tour details found!');
        }

        const recommendedSnap = await getDocs(collection(db, 'recommendedTours'));
        const recommendedData = recommendedSnap.docs.map(doc => doc.data());
        setRecommendedTours(recommendedData);
      } catch (err) {
        console.error('Error fetching tour details:', err);
      }
    };

    fetchTourDetailsAndImages();
  }, [tourId]);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      if (show !== isScrolled) setIsScrolled(show);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  // 3. Handlers
  const handleBookNowClick = () => {
    setShowBookingWidget(true);
    const bookingUrl = `https://bw.trekksoft.com/views/window.html?type=activity-booking&id=${tourId}&clientId=BOOKIE-5badffc8663be9c7621fe18cf&token=f644f78038045737f3c2da77ea17bf4521b12a1ccdc0aded`;
    window.open(bookingUrl, 'BookingWindow', 'width=600,height=800,scrollbars=yes,resizable=yes');
  };

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // 4. Layout & Render
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.5 }}
    >
      {/* Mobile Sticky Bottom Bar */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: '#FFFFFF',
            boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
            p: 1.5,
            display: { xs: 'block', md: 'none' },
            borderTop: 1,
            borderColor: 'divider'
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              px: { xs: 1, sm: 2 }
            }}>
              <Box sx={{ flex: 1 }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: theme.brandColors.blue,
                    fontWeight: 600,
                    fontSize: '1rem',
                    mb: 0.25
                  }}
                >
                  {tourDetails.price}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.85rem'
                  }}
                  noWrap
                >
                  {tourDetails.shortTitle}
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={handleBookNowClick}
                sx={{
                  backgroundColor: theme.brandColors.yellow,
                  color: theme.brandColors.blue,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  px: 3,
                  py: 1,
                  fontSize: '0.95rem',
                  '&:hover': {
                    backgroundColor: theme.brandColors.lightYellow,
                  }
                }}
              >
                Book Now
              </Button>
            </Box>
          </Container>
        </Box>
      )}

      {/* Add padding bottom to content for mobile sticky bar */}
      <Box sx={{ pb: isMobile ? '80px' : 0 }}>
        {/* Original content */}
        <Box
          sx={{
            width: '100%',
            height: isMobile ? '60vh' : '50vh',
            overflow: 'hidden',
            position: 'relative',
            mb: 0
          }}
        >
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.2 }}
            src={tourDetails.headerImage}
            alt="Tour Hero"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: isMobile ? 'center center' : 'center center',
              display: 'block'
            }}
          />
        </Box>

        {/* Info Box Below Hero */}
        <Box sx={{
          background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
          py: { xs: 5, md: 8 },
          width: '100%',
          mb: { xs: 3, md: 6 },
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'radial-gradient(circle at 0% 0%, rgba(255,215,0,0.1) 0%, transparent 50%)',
            pointerEvents: 'none'
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 100% 100%, rgba(26,61,143,0.08) 0%, transparent 50%)',
            pointerEvents: 'none'
          }
        }}>
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'center', md: 'flex-start' },
              justifyContent: 'space-between',
              gap: { xs: 5, md: 6 },
              position: 'relative'
            }}>
              <Box 
                component={motion.div}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                sx={{ 
                  flex: 1, 
                  textAlign: { xs: 'center', md: 'left' },
                  maxWidth: '800px'
                }}
              >
                <Typography 
                  variant="h2" 
                  component="h1"
                  sx={{
                    color: theme.brandColors.blue,
                    fontWeight: 800,
                    mb: 3,
                    lineHeight: 1.1,
                    fontSize: { xs: '2.25rem', sm: '2.75rem', md: '3.75rem' },
                    letterSpacing: '-0.02em',
                    textShadow: '0 2px 4px rgba(0,0,0,0.08)'
                  }}
                >
                  {tourDetails.shortTitle}
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{
                    fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem' },
                    color: 'text.secondary',
                    lineHeight: 1.7,
                    maxWidth: '800px',
                    position: 'relative',
                    zIndex: 1,
                    textShadow: '0 1px 2px rgba(0,0,0,0.04)'
                  }}
                >
                  {tourDetails.shortDescription.replace(/\. Duration:.*$/, '')}
                </Typography>
              </Box>
              <Box 
                component={motion.div}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: { xs: 'center', md: 'flex-end' },
                  gap: 2,
                  position: 'relative'
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleBookNowClick}
                    sx={{
                      backgroundColor: theme.brandColors.yellow,
                      color: theme.brandColors.blue,
                      fontWeight: 700,
                      px: { xs: 5, md: 7 },
                      py: { xs: 2, md: 2.5 },
                      fontSize: { xs: '1.1rem', md: '1.25rem' },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                      borderRadius: '12px',
                      boxShadow: `0 4px 20px ${theme.brandColors.yellow}40`,
                      position: 'relative',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                        transform: 'translateX(-100%)',
                        transition: 'transform 0.5s'
                      },
                      '&:hover': {
                        backgroundColor: theme.brandColors.lightYellow,
                        transform: 'translateY(-2px)',
                        boxShadow: `0 6px 24px ${theme.brandColors.yellow}60`,
                        '&::before': {
                          transform: 'translateX(100%)'
                        }
                      }
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        fontWeight: 800,
                        textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }}
                    >
                      Book Now
                    </Box>
                    <Box 
                      component="span" 
                      sx={{ 
                        fontSize: { xs: '0.9rem', md: '1rem' },
                        opacity: 0.9,
                        fontWeight: 600
                      }}
                    >
                      ${tourDetails.price.replace(/[^0-9]/g, '')} per person
                    </Box>
                  </Button>
                </motion.div>

              </Box>
            </Box>
          </Container>
        </Box>

        {/* --- MAIN CONTENT WRAPPER --- */}
        <Grid
          container
          spacing={4}
          sx={{ px: { xs: 2, md: 10 }, mb: 0 }}
        >
          {/* --- LEFT COLUMN: Description, Inclusions, Highlights, Etc. --- */}
          <Grid item xs={12} md={7}>
            {/* Duration/Price/Days quick info boxes */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {[
                { icon: AccessTimeIcon, label: 'Duration', value: tourDetails.duration },
                { icon: AttachMoneyIcon, label: 'Price', value: tourDetails.price.replace(' per person', '') },
                { icon: CalendarTodayIcon, label: 'Days', value: tourDetails.days }
              ].map((item, idx) => (
                <Grid item xs={4} key={idx}>
                  <Card
                    component={motion.div}
                    whileHover={{ scale: 1.02 }}
                    sx={{
                      boxShadow: 2,
                      p: { xs: 1.5, sm: 2 },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'white',
                      height: '100%',
                      textAlign: 'center'
                    }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        mb: { xs: 0.5, sm: 1 }
                      }}
                    >
                      <item.icon sx={{ 
                        color: theme.brandColors.blue, 
                        fontSize: { xs: '1.5rem', sm: '1.8rem' }
                      }} />
                    </Box>
                    <Box sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%'
                    }}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontWeight: 700, 
                          color: theme.brandColors.blue,
                          fontSize: { xs: '0.75rem', sm: '0.875rem' },
                          textAlign: 'center',
                          mb: 0.5,
                          lineHeight: 1.2
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          textAlign: 'center',
                          lineHeight: 1.2,
                          width: '100%',
                          px: 0.5
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {/* Full Description */}
            <Card sx={{ p: { xs: 2, md: 3 }, boxShadow: 3, mb: 4, bgcolor: 'white' }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}
              >
                Tour Details
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '1rem', md: '1.05rem' },
                  lineHeight: 1.7,
                  mb: 2
                }}
              >
                {tourDetails.description}
              </Typography>

              <Divider sx={{ my: 3 }} />

              {/* Requirements */}
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Requirements
              </Typography>
              {tourDetails.requirements && tourDetails.requirements.length > 0 ? (
                tourDetails.requirements.map((req, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
                    • {req}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No special requirements.</Typography>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Included */}
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Included
              </Typography>
              {tourDetails.included && tourDetails.included.length > 0 ? (
                tourDetails.included.map((inc, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
                    • {inc}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No information available.</Typography>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Highlights */}
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Highlights
              </Typography>
              {tourDetails.highlights && tourDetails.highlights.length > 0 ? (
                tourDetails.highlights.map((highlight, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
                    • {highlight}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No highlights listed.</Typography>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Traveler Tips */}
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Traveler Tips
              </Typography>
              {tourDetails.travelerTips && tourDetails.travelerTips.length > 0 ? (
                tourDetails.travelerTips.map((tip, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
                    • {tip}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No tips provided.</Typography>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Book Now Button */}
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mt: 4 
              }}>
                <Button
                  variant="contained"
                  onClick={handleBookNowClick}
                  sx={{
                    backgroundColor: theme.brandColors.yellow,
                    color: theme.brandColors.blue,
                    fontWeight: 600,
                    px: { xs: 4, md: 6 },
                    py: { xs: 1.5, md: 2 },
                    fontSize: { xs: '1.1rem', md: '1.2rem' },
                    '&:hover': {
                      backgroundColor: theme.brandColors.lightYellow,
                    }
                  }}
                >
                  Book Now
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* --- RIGHT COLUMN: Gallery & Booking Widget --- */}
          <Grid item xs={12} md={5}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                overflow: 'hidden',
                mb: 4,
                bgcolor: 'white',
                display: { xs: 'none', md: 'block' }
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}
                >
                  Book This Tour
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Box sx={{ mb: 4 }}>
                  <TrekksoftBookingWidget tourId={tourId} />
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                overflow: 'hidden',
                mb: 4,
                bgcolor: 'white'
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}
                >
                  Photo Gallery
                </Typography>
                <Divider sx={{ mb: 3 }} />

                <Grid container spacing={2}>
                  {galleryImages
                    // Show up to 6 images for example
                    .slice(0, 6)
                    .map((image, index) => (
                      <Grid
                        item
                        xs={6}
                        key={index}
                        onClick={() => handleImageClick(index)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: { xs: '100px', sm: '150px', md: '180px' },
                            overflow: 'hidden',
                            borderRadius: 2,
                            boxShadow: 1,
                            '& img': {
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block'
                            }
                          }}
                        >
                          <img src={image} alt={`Gallery image ${index + 1}`} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Lightbox for images */}
        {isOpen && (
          <Lightbox
            mainSrc={galleryImages[photoIndex]}
            nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
            prevSrc={
              galleryImages[
                (photoIndex + galleryImages.length - 1) % galleryImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + galleryImages.length - 1) % galleryImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleryImages.length)
            }
          />
        )}

        {/* Recommended Tours */}
        <RecommendedTours excludeTourId={tourId} />

        {/* Floating Booking Widget */}
        <FloatingBookingWidget 
          tourDetails={tourDetails}
          onBookNow={handleBookNowClick}
        />
      </Box>
    </motion.div>
  );
}

export default TemplateTourPage;