import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Card, CardContent, Typography, Box, CircularProgress, Alert, Chip, Collapse, IconButton, Tabs, Tab } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MonthlyRevenueByTourDesk = () => {
    const [chartData, setChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: []
    });
    const [selectedChannels, setSelectedChannels] = useState(['All Channels']);
    const [selectedYear, setSelectedYear] = useState('');
    const [channelOptions, setChannelOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [channelRevenueMap, setChannelRevenueMap] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate] = useState(new Date());
    const [showChannels, setShowChannels] = useState(false);
    const [availableChannels, setAvailableChannels] = useState([]);

    const getChannelColor = (channelName) => {
        const colorMap = {
            'All Channels': '#FF6384',
            'Direct Bookings': '#36A2EB',
            'Viator': '#4BC0C0',
            'Expedia': '#FFCE56',
            'GetYourGuide': '#9966FF',
            'TripAdvisor': '#FF9F40'
        };
        
        if (colorMap[channelName]) return colorMap[channelName];
        
        let hash = 0;
        for (let i = 0; i < channelName.length; i++) {
            hash = channelName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        return `hsl(${h}, 70%, 60%)`;
    };

    const fetchYearData = async (year) => {
        try {
            const monthlyDocRef = doc(db, `tours-data/revenue/${year}/monthly`);
            const monthlyDoc = await getDoc(monthlyDocRef);
            
            if (!monthlyDoc.exists()) {
                console.log(`No data found for ${year}`);
                return {};
            }

            const data = monthlyDoc.data();
            let channelData = {};

            // Process monthly data
            for (let month = 1; month <= 12; month++) {
                if (data[month]?.companyStats) {
                    Object.entries(data[month].companyStats).forEach(([channelName, stats]) => {
                        if (!channelData[channelName]) {
                            channelData[channelName] = new Array(12).fill(0);
                        }
                        channelData[channelName][month - 1] = stats.revenue;
                    });
                }
            }

            return channelData;
        } catch (error) {
            console.error(`Error fetching data for ${year}:`, error);
            return {};
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            const currentYear = currentDate.getFullYear();
            const years = Array.from({length: 4}, (_, i) => currentYear - i);
            let localChannelRevenueMap = {};

            for (const year of years) {
                const yearData = await fetchYearData(year);
                Object.entries(yearData).forEach(([channelName, monthlyData]) => {
                    if (!localChannelRevenueMap[channelName]) {
                        localChannelRevenueMap[channelName] = {};
                    }
                    localChannelRevenueMap[channelName][year] = monthlyData;
                });
            }

            setChannelRevenueMap(localChannelRevenueMap);
            setChannelOptions(Object.keys(localChannelRevenueMap));
            setYearOptions(years);
            setSelectedChannels(['All Channels']);
            setSelectedYear(years[0]);
            setIsLoading(false);
        };

        fetchAllData();
    }, [currentDate]);

    useEffect(() => {
        if (!selectedYear || !channelRevenueMap) return;
        
        const channelsWithData = Object.entries(channelRevenueMap)
            .filter(([_, yearData]) => yearData[selectedYear])
            .filter(([_, yearData]) => yearData[selectedYear].some(revenue => revenue > 0))
            .map(([channelName]) => channelName);

        setAvailableChannels(channelsWithData);
        
        setSelectedChannels(prev => {
            const stillAvailable = prev.filter(channel => 
                channel === 'All Channels' || channelsWithData.includes(channel)
            );
            return stillAvailable.length > 0 ? stillAvailable : ['All Channels'];
        });
    }, [selectedYear, channelRevenueMap]);

    useEffect(() => {
        if (!selectedYear || !channelRevenueMap) return;

        let datasets = [];
        
        if (selectedChannels.includes('All Channels')) {
            const totalRevenue = new Array(12).fill(0);
            
            Object.entries(channelRevenueMap).forEach(([channelName, channelData]) => {
                const yearData = channelData[selectedYear] || new Array(12).fill(0);
                yearData.forEach((revenue, month) => {
                    totalRevenue[month] += revenue;
                });
            });

            datasets = [{
                label: 'All Sales Channels Combined',
                data: totalRevenue,
                borderColor: getChannelColor('All Channels'),
                backgroundColor: getChannelColor('All Channels'),
                tension: 0.4,
                fill: false,
                borderWidth: 2,
                pointRadius: 3,
                pointHoverRadius: 5
            }];
        } else {
            datasets = selectedChannels.map(channelName => ({
                label: channelName,
                data: channelRevenueMap[channelName]?.[selectedYear] || new Array(12).fill(0),
                borderColor: getChannelColor(channelName),
                backgroundColor: getChannelColor(channelName),
                tension: 0.4,
                fill: false,
                borderWidth: 2,
                pointRadius: 3,
                pointHoverRadius: 5
            }));
        }

        setChartData(prevData => ({
            ...prevData,
            datasets
        }));
    }, [selectedChannels, selectedYear, channelRevenueMap]);

    const handleChannelSelection = (channelName) => {
        if (channelName === 'All Channels') {
            setSelectedChannels(['All Channels']);
        } else if (channelName === 'Select All') {
            // Select all available channels except 'All Channels'
            setSelectedChannels(availableChannels);
        } else {
            setSelectedChannels(prev => {
                const newSelection = prev.filter(t => t !== 'All Channels');
                if (newSelection.includes(channelName)) {
                    const filtered = newSelection.filter(t => t !== channelName);
                    return filtered.length === 0 ? ['All Channels'] : filtered;
                } else {
                    return [...newSelection, channelName];
                }
            });
        }
    };

    const handleYearChange = (_, newValue) => {
        setSelectedYear(newValue);
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        size: 12
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#e0e0e0'
                },
                ticks: {
                    font: {
                        size: 12
                    }
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#e0e0e0'
                },
                ticks: {
                    callback: (value) => new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0
                    }).format(value),
                    font: {
                        size: 12
                    }
                }
            }
        }
    };

    return (
        <Card sx={{ width: '100%', p: 2, backgroundColor: '#ffffff' }}>
            <CardContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Monthly revenue breakdown by sales channel - updated {currentDate.toLocaleDateString('en-US', { 
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })}
                </Alert>

                {isLoading ? (
                    <Box display="flex" justifyContent="center" my={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Monthly Revenue by Sales Channel
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ mb: 2 }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 1,
                                    cursor: 'pointer'
                                }} 
                                onClick={() => setShowChannels(!showChannels)}
                                >
                                    <TimelineIcon sx={{ mr: 1 }} />
                                    <Typography variant="subtitle2" component="span">
                                        Select Sales Channels
                                    </Typography>
                                    <IconButton 
                                        size="small" 
                                        sx={{ ml: 1 }}
                                    >
                                        {showChannels ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Box>
                                
                                <Collapse in={showChannels}>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        flexWrap: 'wrap', 
                                        gap: 1,
                                        mt: 1,
                                        '& .MuiChip-root': {
                                            borderRadius: 1,
                                            backgroundColor: '#f0f0f0',
                                            '&.Mui-selected': {
                                                backgroundColor: '#1976d2',
                                                color: 'white'
                                            }
                                        }
                                    }}>
                                        <Chip
                                            label="All Channels"
                                            onClick={() => handleChannelSelection('All Channels')}
                                            variant={selectedChannels.includes('All Channels') ? 'filled' : 'outlined'}
                                            className={selectedChannels.includes('All Channels') ? 'Mui-selected' : ''}
                                        />
                                        <Chip
                                            label="Select All"
                                            onClick={() => handleChannelSelection('Select All')}
                                            variant={selectedChannels.length === availableChannels.length && !selectedChannels.includes('All Channels') ? 'filled' : 'outlined'}
                                            className={selectedChannels.length === availableChannels.length && !selectedChannels.includes('All Channels') ? 'Mui-selected' : ''}
                                        />
                                        {availableChannels.map((channel) => (
                                            <Chip
                                                key={channel}
                                                label={channel}
                                                onClick={() => handleChannelSelection(channel)}
                                                variant={selectedChannels.includes(channel) ? 'filled' : 'outlined'}
                                                className={selectedChannels.includes(channel) ? 'Mui-selected' : ''}
                                            />
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>

                            <Tabs
                                value={selectedYear}
                                onChange={handleYearChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                {yearOptions.map((year) => (
                                    <Tab
                                        key={year}
                                        label={year}
                                        value={year}
                                    />
                                ))}
                            </Tabs>
                        </Box>

                        <Box sx={{ height: '400px' }}>
                            <Line data={chartData} options={chartOptions} />
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default MonthlyRevenueByTourDesk;
